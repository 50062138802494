import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, catchError, map, of as observableOf, switchMap } from 'rxjs';

import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import {
  ClaimCodeValidationError,
  PrepaidEnrollmentErrorRoutingProperties,
  PrepaidEnrollmentService,
} from '@app/registration/prepaid-enrollment.service';
import { HORNBILL_CLAIM_CODE_PARAM_KEY } from '@app/shared/hornbill-test-params';

import { MODULE_HOME_PAGE } from './mixpanel.constants';
@Injectable()
export class HomeScreenClaimCodeRoutingGuardService implements CanActivate {
  constructor(
    private router: Router,
    private linksService: LinksService,
    private launchDarklyService: LaunchDarklyService,
    private prepaidEnrollmentService: PrepaidEnrollmentService,
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const currentQueryParams = activatedRouteSnapshot.queryParams;
    const claimCode = currentQueryParams[HORNBILL_CLAIM_CODE_PARAM_KEY];

    if (!claimCode) {
      return observableOf(true);
    }

    return this.launchDarklyService.featureFlag$(FeatureFlags.HORNBILL_ENROLLMENT_P0_ENABLED, false).pipe(
      switchMap(flagEnabled => {
        if (!flagEnabled) {
          return observableOf(true);
        }

        return this.prepaidEnrollmentService.isValid$(claimCode).pipe(
          map((isValid: boolean) => {
            if (!isValid) {
              throw new ClaimCodeValidationError('Could not validate claim code');
            }
            return this.router.createUrlTree([this.linksService.prepaidEnrollmentConversion], {
              queryParams: currentQueryParams,
            });
          }),

          catchError(e =>
            this.prepaidEnrollmentService.getErrorRoute$({
              error: e,
              source: MODULE_HOME_PAGE,
              claimCode: claimCode,
            } as PrepaidEnrollmentErrorRoutingProperties),
          ),
        );
      }),
    );
  }
}
