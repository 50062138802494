import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { defer, from, iif, Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { RollbarErrorHandler } from '@app/core/rollbar.service';
import { StepName } from '@app/registration/enterprise/registration-step-name';

@Injectable({ providedIn: 'root' })
export class InitialStepNameResolver implements Resolve<string> {
  constructor(
    private launchDarklyService: LaunchDarklyService,
    private rollbar: RollbarErrorHandler,
    private authService: AuthService,
  ) {}

  resolve(_route: ActivatedRouteSnapshot): Observable<string> {
    if (this.authService.isLoggedIn()) {
      return observableOf(StepName.workEmail.valueOf());
    }
    const setAnonymousUuid$ = defer(() => from(this.launchDarklyService.setAnonymousUserUUID()));
    return iif(() => this.launchDarklyService.isAnonymousUnkeyedUser(), setAnonymousUuid$, observableOf(null)).pipe(
      switchMap(() =>
        this.launchDarklyService.featureFlag$<string>(FeatureFlags.ENTERPRISE_PERSONAL_DETAILS_VERIFICATION, 'OFF'),
      ),
      take(1),
      map((response: string) => {
        if (response === 'ON VARIANT') {
          return StepName.personalDetails.valueOf();
        } else {
          return StepName.workEmail.valueOf();
        }
      }),
      catchError(e => {
        this.rollbar.handleError(e);
        return observableOf(StepName.workEmail.valueOf());
      }),
    );
  }
}
