import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://washington.app.1life.com',
  myoneServer: 'https://washington.app.1life.com',
  rollbarEnvironment: 'onelife-washington',
  stripePublishableKey: 'pk_test_1BgdJYIyf82JQC58S4yInVou',
  launchDarklyClientId: '605034c0e13fe80c650be9bc',
};
