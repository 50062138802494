import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { LegalDocumentsForBeneficiary } from '@app/core/legal-documents/__generated__/LegalDocumentsForBeneficiary';
import { AdministratorLegalDocGraphQL } from '@app/core/legal-documents/administrator-legal-doc-graphql.service';
import {
  BeneficiaryLegalDocGraphQL,
  BeneficiaryQueryVariables,
} from '@app/core/legal-documents/beneficiary-legal-doc-graphql.service';
import { LegalDocumentGraphQLResponse } from '@app/core/legal-documents/legal-document-graphql-response';
import { SignLegalDocGraphQL } from '@app/core/legal-documents/sign-legal-doc-graphql.service';
import { TermsOfService } from '@app/core/legal-documents/terms-of-service';
import { BeneficiaryForLegalDoc } from '@app/shared';
import { FlashService, MessageType } from '@app/shared/flash.service';

export interface DocumentSigner {
  email: string;
  firstName: string;
  lastName: string;
  id: number | string;
  type: string;
}

export interface RestResponse {
  error?: string;
}

/**
 * A façade of the Apollo `QueryRef` object, for getting the results of a legal
 * doc GraphQL query.
 */

export class TermsOfServiceQueryRef<ApolloResponse, Variables = EmptyObject> {
  constructor(
    private queryRef: QueryRef<ApolloResponse>,
    private service: LegalDocumentsService,
    private tosMapper: (result: ApolloResponse) => Observable<TermsOfService[]>,
  ) {}

  get valueChanges(): Observable<TermsOfService[]> {
    return this.queryRef.valueChanges.pipe(
      tap({
        error: () => this.service.handleError(),
      }),
      switchMap(result => {
        if (result.errors) {
          return observableThrowError(result.errors);
        } else if (result.data) {
          return this.tosMapper(result.data);
        } else {
          return observableOf(null);
        }
      }),
      filter(docs => docs !== null),
    );
  }

  refetch(variables?: Variables) {
    this.queryRef.refetch(variables);
  }
}

/**
 * This service manages the loading and signing of {TermsOfService} (a class
 * that can actually be used to represent any legal document, not just a TOS).
 * This service is intended to be used in one of three contexts:
 *
 * * when the user is signing for his- or herself,
 * * when the user is signing as an administrator on behalf of a beneficiary
 *   (e.g., as a parent on behalf of a child), or
 * * when the user is signing as an administrator but _logged in_ to a
 *   beneficiary account.
 *
 * Depending on which of these is true, you would use different methods within
 * this service to load and sign your legal documents.
 *
 * The `getFor...` methods all return an Apollo `QueryRef`. Implementers should
 * store this `QueryRef` so that they can re-fetch legal documents after signing
 * them. The `QueryRef` is also used to retrieve the fetched legal documents
 * when they become available.
 *
 * Before an administrator can sign for a beneficiary, they must mark themselves
 * as a legal guardian for that beneficiary. This is done with the
 * `setLegalGuardian..` methods.
 *
 * If an error occurs when loading or signing legal documents, it is given to
 * the {FlashService} for display to the user.
 */

@Injectable()
export class LegalDocumentsService {
  constructor(
    private administratorTOSGraphQL: AdministratorLegalDocGraphQL,
    private APIService: ApiService,
    private beneficiaryTOSGraphQL: BeneficiaryLegalDocGraphQL,
    private flashService: FlashService,
    private signTOSGraphQL: SignLegalDocGraphQL,
  ) {}

  /**
   * Loads legal documents (signed and unsigned) for the currently logged-in
   * user.
   *
   * @return An Apollo `QueryRef` that can be used to access the loaded legal
   *   documents and re-fetch updated legal documents after signing them.
   */

  getForSelf(): TermsOfServiceQueryRef<LegalDocumentGraphQLResponse> {
    const query = this.administratorTOSGraphQL.watch();
    return new TermsOfServiceQueryRef<LegalDocumentGraphQLResponse>(query, this, result =>
      observableOf(result.legalDocuments.map(doc => TermsOfService.fromGraphQL(doc))),
    );
  }

  /**
   * Loads legal documents (signed and unsigned) for a user that the currently
   * logged-in user is an administrator for.
   *
   * @param beneficiary The user the logged-in user is signing on behalf of.
   * @return An Apollo `QueryRef` that can be used to access the loaded legal
   *   documents and re-fetch updated legal documents after signing them.
   */

  getForBeneficiary(
    beneficiary: BeneficiaryForLegalDoc,
  ): TermsOfServiceQueryRef<LegalDocumentsForBeneficiary, BeneficiaryQueryVariables> {
    const query = this.beneficiaryTOSGraphQL.watch({ beneficiaryId: beneficiary.id });
    return new TermsOfServiceQueryRef<LegalDocumentsForBeneficiary, BeneficiaryQueryVariables>(query, this, result =>
      observableOf(result.legalDocumentsForBeneficiary.map(doc => TermsOfService.fromGraphQL(doc))),
    );
  }

  /**
   * Makes an API call that records the currently logged-in user as a legal
   * guardian for the given beneficiary.
   *
   * @param beneficiary The user that the currently logged-in user is a legal
   *   guardian for.
   * @return An observable of the API response.
   */

  setLegalGuardianForBeneficiaryWhenLoggedInAsAdministrator(beneficiary: BeneficiaryForLegalDoc): Observable<object> {
    const body = {
      relationship: { beneficiary_id: beneficiary.id },
    };
    return this.setRelationship(body);
  }

  /**
   * Makes an API call that records the a given user as the legal guardian for
   * the currently logged-in user.
   *
   * @param administrator The user that is a legal guardian for the currently
   *   logged-in user.
   * @return An observable of the API response.
   */

  setLegalGuardianForBeneficiaryWhenLoggedInAsBeneficiary(administrator: DocumentSigner): Observable<object> {
    const body = {
      relationship: { administrator_id: administrator.id, administrator_type: administrator.type },
    };
    return this.setRelationship(body);
  }

  /**
   * Signs a given legal document for the currently logged-in user.
   *
   * @param document The legal document to sign.
   * @return An observable of the GraphQL API response.
   */

  signForSelf(document: TermsOfService): Observable<boolean> {
    return this.signTOSGraphQL.mutate({ type: document.type }).pipe(map(response => !response.errors));
  }

  /**
   * Signs a given legal document on behalf of a given beneficiary. This method
   * should be used when the logged-in user is the administrator.
   *
   * @param doc The legal document to sign.
   * @param beneficiary The person to sign on behalf of.
   * @return An observable of the GraphQL API response.
   */

  signForBeneficiaryWhenLoggedInAsAdministrator(
    doc: TermsOfService,
    beneficiary: BeneficiaryForLegalDoc,
  ): Observable<boolean> {
    return this.APIService.post<RestResponse>(
      `/api/v2/patient/administrator/beneficiaries/${beneficiary.id}/tos_signatures.json`,
      {
        tos: { type: doc.type, version: doc.version },
        tos_signature: { user_id: beneficiary.id },
      },
    ).pipe(map(response => !response.error));
  }

  /**
   * Signs a given legal document on behalf of the currently logged-in
   * beneficiary. This method should be used when the logged-in user is the
   * beneficiary.
   *
   * @param doc The legal document to sign.
   * @param beneficiary The person to sign on behalf of.
   * @param signer The person signing on behalf.
   * @return An observable of the GraphQL API response.
   */

  signForBeneficiaryWhenLoggedInAsBeneficiary(
    doc: TermsOfService,
    beneficiary: BeneficiaryForLegalDoc,
    signer: DocumentSigner,
  ): Observable<boolean> {
    return this.APIService.post<RestResponse>('/api/v2/patient/beneficiary/tos_signatures.json', {
      tos: { type: doc.type, version: doc.version },
      tos_signature: { user_id: beneficiary.id, signer_id: signer.id, signer_type: signer.type },
    }).pipe(map(response => !response.error));
  }

  private setRelationship(body: Record<string, any>): Observable<object> {
    return this.APIService.post<object>('/api/v2/pediatric/current_patient/relationship', body).pipe(
      catchError(error => observableThrowError(error)),
    );
  }

  handleError() {
    this.flashService.addFlashMessage(
      "We've encountered an issue submitting your request. Please log out and try again.",
      MessageType.ERROR,
    );
  }
}
