import isNil from 'lodash-es/isNil';

function sanitizeParams(params: Record<string, string>): Record<string, string> {
  const sanitizedParams: Record<string, string> = {};

  Object.keys(params).map(key => {
    if (params[key].length > 0) {
      sanitizedParams[key] = params[key];
    }
  });

  return sanitizedParams;
}

function constructQueryParams(options: Record<string, string>): string {
  const sanitizedParams = sanitizeParams(options);
  const queryParams = new URLSearchParams(sanitizedParams).toString();
  return queryParams ? `?${queryParams}` : '';
}

export function appendQueryParamsToPath(path?: string, options: Record<string, string> = {}): string {
  return isNil(path) ? path : path + constructQueryParams(options);
}
