<om-simple-navbar></om-simple-navbar>
<ng-container [ngSwitch]="errorCode">
  <ng-container *ngSwitchCase="RegistrationErrorCodes.PrepaidEnrollmentInvalidCode">
    <om-error-state title="Your membership needs updating">
      <ng-template om-error-state-body-content>
        <div class="mb-4">
          <p>
            Your membership isn't active at the moment. This could be because the payment method failed or the
            membership manager made a change to this account.
          </p>
          <p>The One Medical membership may need to be purchased again on Amazon.</p>
        </div>
        <div class="d-flex justify-content-center">
          <omgui-button
            variant="primary"
            data-cy="manage-amazon-btn"
            size="fill-parent"
            class="col-md-6 col-sm-8 col m-3"
            [newTab]="true"
            [externalLink]="HORNBILL_MANAGE_ON_AMAZON_URL"
          >
            Visit Amazon<omgui-external-link-icon></omgui-external-link-icon>
          </omgui-button>
        </div>
      </ng-template>
    </om-error-state>
  </ng-container>

  <ng-container *ngSwitchCase="RegistrationErrorCodes.PrepaidEnrollmentCodeClaimed">
    <om-error-state title="Membership already created">
      <ng-template om-error-state-body-content>
        <p>It looks like this membership has already been set up.</p>
        <p>
          If you purchased multiple memberships and are looking to invite others, log in to the account that manages
          your family's One Medical memberships. You can send invitations from the Membership & Billing page on One
          Medical.
        </p>
        <p>Questions? Email our Membership Advisors at <a [href]="links.maEmail">ma@onemedical.com</a></p>
        <div class="d-flex justify-content-center">
          <omgui-button
            [internalLink]="links.login"
            variant="primary"
            data-cy="existing-membership-error-login"
            size="fill-parent"
            class="col-md-6 col-sm-8 col m-3"
          >
            Log In
          </omgui-button>
        </div>
      </ng-template>
    </om-error-state>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <om-error-state title="Something went wrong">
      <ng-template om-error-state-body-content>
        <p>A glitch on our end is causing a problem. Wait a minute and try again.</p>
        <p>If the issue persists, contact us at <a [href]="links.techsupportEmail">techsupport@onemedical.com</a></p>
      </ng-template>
    </om-error-state>
  </ng-container>
</ng-container>
