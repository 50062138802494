<div class="col-12 row p-0 m-0">
  <div class="col-12 col-md-8 offset-md-2 text-center">
    <h1>Verify your email</h1>
    <div class="mb-4">
      <p>Enter the code we've just sent to {{ preferredEmail }} to verify your One Medical account.</p>
    </div>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="mb-4">
        <om-label-wrapper class="" label="" [hasError]="hasError()" [errorMessage]="errorMessage">
          <input
            type="text"
            class="form-control text-field mb-1"
            placeholder="Code"
            formControlName="verificationCode"
            data-cy="verification-code"
            required="true"
            autofocus
            (keypress)="filterNonNumeric($event)"
            maxlength="6"
          />
        </om-label-wrapper>
      </div>
      <input type="hidden" formControlName="skip" />
      <div class="text-center col-12 col-md-6 offset-md-3 p-0 mb-4">
        <om-submit-button class="col-4 p-0" label="Continue" [submitting]="submitting"></om-submit-button>
      </div>
      <div class="text-center p-0 col-12 col-md-6 offset-md-3 mb-4">
        <a
          class="btn col-12 btn-outline-primary"
          data-cy="skip-verification"
          aria-label="Skip for now"
          role="button"
          tabindex="0"
          (click)="onSkip()"
        >
          Skip for now
        </a>
      </div>
    </form>
    <form *ngIf="formGroupState" [ngrxFormState]="formGroupState" (submit)="onSubmit()">
      <div class="mb-4">
        <om-label-wrapper class="" label="" [hasError]="hasError()" [errorMessage]="errorMessage">
          <input
            data-cy="verification-code"
            type="text"
            class="form-control text-field mb-1"
            placeholder="Code"
            [ngrxFormControlState]="formGroupState.controls.verificationCode"
            required="true"
            autofocus
            (keypress)="filterNonNumeric($event)"
            maxlength="6"
          />
        </om-label-wrapper>
      </div>
      <input type="hidden" [ngrxFormControlState]="formGroupState.controls.skip" />
      <div class="text-center col-12 col-md-6 offset-md-3 p-0 mb-4">
        <om-submit-button class="p-0" label="Continue" [submitting]="submitting"></om-submit-button>
      </div>
      <div class="text-center p-0 col-12 col-md-6 offset-md-3 mb-4">
        <a
          type="button"
          class="btn col-12 btn-outline-primary"
          data-cy="skip-verification"
          role="button"
          tabindex="0"
          aria-label="Skip for now"
          (click)="onSkip()"
        >
          Skip for now
        </a>
      </div>
    </form>
    <div class="col-12 p-0 lh-175">
      Didn't get the email?
      <a href="#" data-cy="resend-email" aria-label="Resend email" (click)="onResendEmail($event)"> Resend email </a>
      <br />
      Need help? Contact
      <a
        (click)="onEmailTechSupport($event)"
        href="mailto:techsupport@onemedical.com"
        aria-label="Contact technical support"
      >
        techsupport@onemedical.com
      </a>
    </div>
  </div>
</div>
