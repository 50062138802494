import each from 'lodash-es/each';
import every from 'lodash-es/every';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import reject from 'lodash-es/reject';
import remove from 'lodash-es/remove';
import set from 'lodash-es/set';

import {
  PatientSurveyAnswer,
  SurveyAnswerCheckboxCustomBehavior,
  SurveyAnswerDatePickerCustomBehavior,
  SurveyAnswerKind,
} from '../../../__generated__/globalTypes';
import {
  SurveySummary_surveySummary_survey_sections_questions,
  SurveySummary_surveySummary_survey_sections_questions_answers,
  SurveySummary_surveySummary_survey_sections_questions_automaticallyCalculatedAnswers,
  SurveySummary_surveySummary_survey_sections_questions_suggestedAnswers,
} from './__generated__/SurveySummary';
import { FsaHsaAnswerText, FsaHsaFields } from './fsa-hsa-question/fsa-hsa-question.component';

export class PatientPredefinedAnswer implements PatientSurveyAnswer {
  answerId: string;

  constructor({ answerId }: { answerId?: string } = {}) {
    this.answerId = answerId;
  }
}

export class PatientCustomAnswer implements PatientSurveyAnswer {
  surveyQuestionId: string;
  answerText: string;
  kind: SurveyAnswerKind;

  constructor({
    surveyQuestionId,
    answerText,
    kind,
  }: {
    surveyQuestionId?: string;
    answerText?: string;
    kind?: SurveyAnswerKind;
  } = {}) {
    this.surveyQuestionId = surveyQuestionId;
    this.answerText = answerText;
    this.kind = kind;
  }
}

export enum SurveyIds {
  RTW_DAILY_SCREENER = '27',
  MEDICAL_RECORDS_RELEASE = '95',
  TRAVEL = '3',
  FSA_HSA_REIMBURSEMENT = '100',
}

export enum SurveySystemNames {
  RTW_DAILY_SCREENER = '27',
  MEDICAL_RECORDS_RELEASE = '95',
  TRAVEL = '3',
  FSA_HSA_REIMBURSEMENT = '100',
}

export enum SurveyQuestionControlType {
  Checkboxes,
  DatePicker,
  DateString,
  DateRange,
  FreeText,
  BinaryChoice,
  MultipleChoice,
  Scale,
  Autocomplete,
  MultiSelect,
  FsaHsa,
}

export class SurveyQuestion {
  id: string;
  name: string;
  allowMultipleAnswers: boolean;
  helperPrompt: string;
  placeholderAnswer: string;
  questionText: string;
  required: boolean;
  iconKey: string;
  questionContext: string;
  answers: SurveyAnswer[] = [];
  automaticallyCalculatedAnswers: SurveySummary_surveySummary_survey_sections_questions_automaticallyCalculatedAnswers[] = [];
  suggestedAnswers: SurveySummary_surveySummary_survey_sections_questions_suggestedAnswers[] = [];

  activated: boolean;
  controlType: SurveyQuestionControlType;
  errorText: string;
  focused: boolean;
  patientAnswers: PatientSurveyAnswer[];

  isSinglePageForm: boolean;

  selectAll: SurveyAnswer;
  selectNone: SurveyAnswer;
  selectAllExcluded: SurveyAnswer;
  defaultSelected: SurveyAnswer;

  static fromGraphQL(
    response: SurveySummary_surveySummary_survey_sections_questions,
    isSinglePageForm = false,
  ): SurveyQuestion {
    const question = new SurveyQuestion();
    question.id = response.id;
    question.name = response.tocHeader;
    question.allowMultipleAnswers = response.allowMultipleAnswers;
    question.helperPrompt = response.helperPrompt;
    question.placeholderAnswer = response.placeholderAnswer;
    question.questionText = response.text;
    question.required = response.required;
    question.iconKey = response.iconKey;
    question.questionContext = response.context;
    question.automaticallyCalculatedAnswers = response.automaticallyCalculatedAnswers;
    question.suggestedAnswers = response.suggestedAnswers;
    question.isSinglePageForm = isSinglePageForm;

    each(response.answers, answer => {
      question.answers.push(SurveyAnswer.fromGraphQL(answer));
    });

    question.errorText = '';
    question.controlType = question.selectControlType();
    question.patientAnswers = question.createPatientAnswers();

    question.selectAll = question.findCustomCheckbox(SurveyAnswerCheckboxCustomBehavior.select_all);
    question.selectNone = question.findCustomCheckbox(SurveyAnswerCheckboxCustomBehavior.select_none);
    question.selectAllExcluded = question.findCustomCheckbox(SurveyAnswerCheckboxCustomBehavior.select_all_excluded);
    question.defaultSelected = question.findCustomCheckbox(SurveyAnswerCheckboxCustomBehavior.default_selected);

    return question;
  }

  private selectControlType(): SurveyQuestionControlType {
    const answers = filter(this.answers, answer => !answer.isCustom);
    if (answers.length === 1) {
      if (answers[0].kind === SurveyAnswerKind.free_text) {
        return SurveyQuestionControlType.FreeText;
      } else if (answers[0].kind === SurveyAnswerKind.date_string) {
        return SurveyQuestionControlType.DateString;
      } else if (answers[0].kind === SurveyAnswerKind.date_range) {
        return SurveyQuestionControlType.DateRange;
      } else if (answers[0].kind === SurveyAnswerKind.date_picker) {
        return SurveyQuestionControlType.DatePicker;
      } else if (answers[0].kind === SurveyAnswerKind.checkbox) {
        return SurveyQuestionControlType.Checkboxes;
      } else if (answers[0].kind === SurveyAnswerKind.fsa_hsa_grid_input) {
        return SurveyQuestionControlType.FsaHsa;
      }
    } else if (every(answers, answer => answer.kind === SurveyAnswerKind.radio)) {
      return SurveyQuestionControlType.MultipleChoice;
    } else if (every(answers, answer => answer.kind === SurveyAnswerKind.binary_radio)) {
      return SurveyQuestionControlType.BinaryChoice;
    } else if (every(answers, answer => answer.kind === SurveyAnswerKind.scale)) {
      return SurveyQuestionControlType.Scale;
    } else if (every(answers, answer => answer.kind === SurveyAnswerKind.checkbox) && this.allowMultipleAnswers) {
      return SurveyQuestionControlType.Checkboxes;
    } else if (
      every(
        answers,
        answer =>
          answer.kind === SurveyAnswerKind.select ||
          answer.kind === SurveyAnswerKind.free_text ||
          answer.kind === SurveyAnswerKind.checkbox,
      )
    ) {
      return SurveyQuestionControlType.Autocomplete;
    }
  }

  private createPatientAnswers(): PatientSurveyAnswer[] {
    const patientAnswers: PatientSurveyAnswer[] = [];

    switch (this.controlType) {
      case SurveyQuestionControlType.BinaryChoice:
      case SurveyQuestionControlType.MultipleChoice:
      case SurveyQuestionControlType.Scale:
        if (this.automaticallyCalculatedAnswers.length > 0) {
          patientAnswers.splice(
            0,
            0,
            ...this.automaticallyCalculatedAnswers.map(autoAnswer => new PatientPredefinedAnswer(autoAnswer)),
          );
        } else if (this.suggestedAnswers.length > 0) {
          patientAnswers.splice(
            0,
            0,
            ...this.suggestedAnswers.map(autoAnswer => new PatientPredefinedAnswer(autoAnswer)),
          );
        } else {
          patientAnswers.push(new PatientPredefinedAnswer());
        }
        break;
      case SurveyQuestionControlType.DatePicker:
        patientAnswers.push(
          new PatientCustomAnswer({
            surveyQuestionId: this.id,
            kind: SurveyAnswerKind.date_picker,
          }),
        );
        break;
      case SurveyQuestionControlType.DateRange:
        patientAnswers.push(
          new PatientCustomAnswer({
            surveyQuestionId: this.id,
            kind: SurveyAnswerKind.date_range,
          }),
        );
        break;
      case SurveyQuestionControlType.DateString:
        patientAnswers.push(
          new PatientCustomAnswer({
            surveyQuestionId: this.id,
            kind: SurveyAnswerKind.date_string,
          }),
        );
        break;
      case SurveyQuestionControlType.FreeText:
        patientAnswers.push(
          new PatientCustomAnswer({
            surveyQuestionId: this.id,
            kind: SurveyAnswerKind.free_text,
          }),
        );
        break;
      case SurveyQuestionControlType.Autocomplete:
      case SurveyQuestionControlType.Checkboxes:
        this.answers.forEach(answer => {
          if (answer.checkboxCustomBehavior === SurveyAnswerCheckboxCustomBehavior.default_selected) {
            patientAnswers.push(new PatientPredefinedAnswer({ answerId: answer.id }));
          }
        });
        break;
      case SurveyQuestionControlType.FsaHsa:
        each(Object.values(FsaHsaFields), field => {
          patientAnswers.push(
            new PatientCustomAnswer({
              surveyQuestionId: this.id,
              kind: SurveyAnswerKind.fsa_hsa_grid_input,
              answerText: JSON.stringify({ group: 0, field, value: '' }),
            }),
          );
        });
        break;
    }

    return patientAnswers;
  }

  getDependentQuestion(): SurveyQuestion | null {
    const selectedAnswer = find(
      this.answers,
      (answer: SurveyAnswer) =>
        !!find(
          this.patientAnswers,
          (patientAnswer: PatientSurveyAnswer) =>
            answer.id === patientAnswer.answerId && answer.dependentQuestion != null,
        ),
    );
    if (selectedAnswer) {
      this.errorText = '';
      return selectedAnswer.dependentQuestion;
    }
  }

  isAutomaticallyAnswered() {
    return this.automaticallyCalculatedAnswers.length > 0;
  }

  isSkippable(): boolean {
    const dependentQuestion = this.getDependentQuestion();
    return this.isAutomaticallyAnswered() && (!dependentQuestion || dependentQuestion.isSkippable());
  }

  isQuestionActivated(): boolean {
    return this.isSinglePageForm || this.activated;
  }

  setFocus() {
    this.focused = true;
  }

  setErrorText(errorMsg: string) {
    this.errorText = errorMsg;
  }

  clearErrorText() {
    this.errorText = '';
  }

  isAnswered(): boolean {
    let answered = false;
    switch (this.controlType) {
      case SurveyQuestionControlType.BinaryChoice:
      case SurveyQuestionControlType.MultipleChoice:
      case SurveyQuestionControlType.Scale:
      case SurveyQuestionControlType.Checkboxes:
      case SurveyQuestionControlType.MultiSelect:
        answered = this.patientAnswers.some(answer => answer.answerId);
        break;
      case SurveyQuestionControlType.DatePicker:
      case SurveyQuestionControlType.DateRange:
      case SurveyQuestionControlType.DateString:
      case SurveyQuestionControlType.FreeText:
        answered = this.patientAnswers.some(answer => answer.answerText?.trim());
        break;
      case SurveyQuestionControlType.Autocomplete:
        answered =
          this.patientAnswers.some(answer => answer.answerId) || this.patientAnswers.some(answer => answer.answerText);
        break;
      case SurveyQuestionControlType.FsaHsa:
        answered = every(this.patientAnswers, answer => {
          const answerText: FsaHsaAnswerText = JSON.parse(answer.answerText);
          if (answerText.field === FsaHsaFields.name || answerText.field === FsaHsaFields.reason) {
            return answerText.value?.length > 0;
          }
          return true;
        });
        break;
    }
    this.unAnsweredAndRequired(answered);
    const dependentQuestion = this.getDependentQuestion();
    if (answered && dependentQuestion) {
      answered = dependentQuestion.isAnswered();
    }

    return answered;
  }

  unAnsweredAndRequired(answered: boolean) {
    if (!answered && this.required) {
      if (this.controlType === SurveyQuestionControlType.FsaHsa) {
        this.setErrorText('For each item or service, please enter a name and medical reason.');
      } else {
        this.setErrorText('Please answer the question before progressing.');
      }
    }
  }

  handleSelectedCheckboxCustomBehaviors(value: string) {
    if (this.selectAll?.id === value) {
      this.resetSurveyAnswers();
    } else if (this.selectAll?.selected && value !== this.selectAllExcluded?.id) {
      this.selectAll?.toggleSelected();
    }

    this.updatePatientAnswers();
  }

  handleUnselectedCheckboxCustomBehaviors(value: string) {
    if (this.selectAll?.id === value) {
      each(
        reject(this.answers, ['checkboxCustomBehavior', SurveyAnswerCheckboxCustomBehavior.select_all_excluded]),
        (answer: SurveyAnswer) => set(answer, 'selected', true),
      );
    } else if (this.selectNone?.id === value) {
      this.resetSurveyAnswers();
      this.selectNone.selected = true;
    } else if (this.selectNone?.id !== value && this.selectNone?.selected) {
      this.selectNone?.toggleSelected();
    } else if (this.areAllOptionsSelectedButSelectAll && this.selectAllExcluded?.id !== value) {
      this.selectAll?.toggleSelected();
    }

    this.updatePatientAnswers();
  }

  private resetSurveyAnswers() {
    each(this.answers, (answer: SurveyAnswer) => set(answer, 'selected', false));
  }

  private updatePatientAnswers() {
    remove(this.patientAnswers, (_patientAnswer: PatientSurveyAnswer) => true);
    each(filter(this.answers, { selected: true }), (answer: SurveyAnswer) => {
      this.patientAnswers.push(new PatientPredefinedAnswer({ answerId: answer.id }));
    });
  }

  private get areAllOptionsSelectedButSelectAll(): boolean {
    if (!this.selectAll) {
      return false;
    }

    const unselected = filter(
      this.answers,
      (answer: SurveyAnswer) =>
        !answer.selected &&
        answer.checkboxCustomBehavior !== SurveyAnswerCheckboxCustomBehavior.select_all &&
        answer.checkboxCustomBehavior !== SurveyAnswerCheckboxCustomBehavior.select_all_excluded,
    );
    return unselected.length === 0;
  }

  findCustomCheckbox(customBehavior: SurveyAnswerCheckboxCustomBehavior): SurveyAnswer {
    return find(this.answers, (answer: SurveyAnswer) => answer.checkboxCustomBehavior === customBehavior);
  }
}

export class SurveyAnswer {
  id: string;
  answerText: string;
  isCustom: boolean;
  kind: SurveyAnswerKind;
  singletonAnswer: boolean;
  checkboxCustomBehavior: SurveyAnswerCheckboxCustomBehavior;
  datePickerCustomBehavior: SurveyAnswerDatePickerCustomBehavior;
  dependentQuestion: SurveyQuestion;
  selected = false;

  static fromGraphQL(response: SurveySummary_surveySummary_survey_sections_questions_answers) {
    const answer = new SurveyAnswer();
    answer.id = response.id;
    answer.answerText = response.answerText;
    answer.isCustom = response.isCustom;
    answer.kind = response.kind;
    answer.checkboxCustomBehavior = response.checkboxCustomBehavior;
    answer.datePickerCustomBehavior = response.datePickerCustomBehavior;
    answer.singletonAnswer = response.singletonAnswer;
    if (response.checkboxCustomBehavior === SurveyAnswerCheckboxCustomBehavior.default_selected) {
      answer.selected = true;
    }
    if (response.dependentQuestion) {
      answer.dependentQuestion = SurveyQuestion.fromGraphQL(
        response.dependentQuestion as SurveySummary_surveySummary_survey_sections_questions,
      );
    }

    return answer;
  }

  toggleSelected() {
    this.selected = !this.selected;
  }
}
