import { Office } from '../shared/office';
import { Provider } from '../shared/provider';
import { AppointmentType } from './appointment-type';
import { Appointment as AppointmentFragment } from './appointments/__generated__/appointments-graphql.fragments.types';
import { PastAppointmentNode } from './appointments/__generated__/past-appointments-graphql.service.types';
import { AppointmentInventory } from './provider-inventories';

export class Appointment {
  id: number;
  differentOnsiteTimezone: boolean;
  reason: string;
  startTime: string;
  timezone: string;
  remoteMeetingLink: string;
  appointmentType?: AppointmentType;
  appointmentInventory?: AppointmentInventory;
  provider: Provider;
  office?: Office;
  state?: string;

  get appointmentTypeId(): number {
    return this.appointmentType?.id;
  }

  get appointmentInventoryId(): number {
    return this.appointmentInventory?.id;
  }

  get officeId(): number {
    return this.office?.id;
  }

  get providerId(): number {
    return this.provider?.id;
  }

  get appointmentTypeName(): string {
    return this.appointmentType?.displayName;
  }

  get remote(): boolean {
    return this.appointmentType?.remote;
  }

  get duration(): number {
    return this.appointmentType?.displayedDuration;
  }

  get isSeenOrTerminal(): boolean {
    return ['cancelled', 'no_show', 'seen'].includes(this.state);
  }

  static fromGraphQL({
    id,
    provider,
    startAt,
    reason,
    timezoneTzinfo,
    isDifferentOnsiteTimezone,
    joinLink,
    appointmentType,
    appointmentInventory,
    office,
    state,
  }: Record<string, any>): Appointment {
    const appointment = new Appointment();
    appointment.id = id;
    appointment.reason = reason;
    appointment.startTime = startAt;
    appointment.timezone = timezoneTzinfo;
    appointment.differentOnsiteTimezone = isDifferentOnsiteTimezone;
    appointment.remoteMeetingLink = joinLink;
    appointment.provider = Provider.fromGraphQL(provider);
    appointment.state = state;

    if (office) {
      appointment.office = Office.fromGraphQL(office);
    }

    if (appointmentType) {
      appointment.appointmentType = AppointmentType.fromGraphQL(appointmentType);
    }

    if (appointmentInventory) {
      appointment.appointmentInventory = AppointmentInventory.fromGraphQL(appointmentInventory);
    }

    return appointment;
  }
}

export type GraphQLAppointment = AppointmentFragment | PastAppointmentNode;
