import { CareTopic } from '@app/shared/care-topic';

import { RoutingStep, RoutingStepVariables } from '../__generated__/RoutingStep';
import { AppointmentRoutingStepName } from './appointment-routing-steps';

export const CARE_OPTION_NAME_BOOK_APPOINTMENT = 'BookAppointment';

export class AppointmentRoutingState {
  appointmentTypeId: number;
  appointmentTypeLocked = false;
  careOptionId: string;
  careOptionLocked = true;
  careOptionName = CARE_OPTION_NAME_BOOK_APPOINTMENT;
  final: boolean;
  reasonForVisit: string;
  rfvRequired: boolean;
  routingSessionId: string;
  serviceAreaId: number;
  stepName: AppointmentRoutingStepName;
  surveyId: string;
  topicName: CareTopic;

  /**
   * Populate the Appointment Routing State from a GraphQL response
   *
   * @param data
   */
  fromGraphQL(data: RoutingStep): void {
    this.final = data.routingStep.final;
    this.routingSessionId = data.routingStep.routingSessionId;
    this.stepName = data.routingStep.stepName as AppointmentRoutingStepName;
    this.topicName = data.routingStep.topicName as CareTopic;

    const { stepContext } = data.routingStep;
    if (stepContext) {
      this.rfvRequired = stepContext.rfvRequired;
      if (stepContext.survey) {
        this.surveyId = stepContext.survey.id;
      }
      if (stepContext.appointmentType) {
        this.appointmentTypeId = parseInt(stepContext.appointmentType.id, 10);
      } else {
        this.appointmentTypeId = null;
      }
    }
  }

  /**
   * Get a collection of GraphQL variables based on the current Appointment Routing State
   *
   * @returns
   */
  toGraphQL(): RoutingStepVariables {
    const variables: RoutingStepVariables = {
      appointmentTypeLocked: this.appointmentTypeLocked,
      careOptionId: this.careOptionId,
      careOptionLocked: this.careOptionLocked,
      careOptionName: this.careOptionName,
      reasonForVisit: this.reasonForVisit,
      routingSessionId: this.routingSessionId,
      stepName: this.stepName,
      topicName: this.topicName,
      surveyId: this.surveyId,
    };
    if (this.appointmentTypeId) {
      variables.appointmentTypeId = this.appointmentTypeId.toString();
    }
    if (this.serviceAreaId) {
      variables.serviceAreaId = this.serviceAreaId.toString();
    }
    return variables;
  }
}
