<form [formGroup]="address">
  <div *ngIf="!fullAddressFormVisible">
    <div class="mb-2">
      <om-address-autocomplete
        #autocomplete
        [existingAddress]="existingAddress"
        [class.has-error]="hasAutocompleteError"
        (addressSelected)="addressSelected($event)"
        [enableAutoFocus]="enableAutoFocus"
        [prefilledRegistration]="prefilledRegistration"
      ></om-address-autocomplete>
    </div>
    <small
      data-cy="error-message"
      class="text-danger mt-2 d-block mb-1"
      *ngIf="!fullAddressFormVisible && hasAutocompleteError"
    >
      Having trouble?
      <a href (click)="showFullAddressForm($event)" data-cy="manual-address">Enter address manually.</a>
    </small>
    <div class="form-group">
      <input
        type="text"
        id="address-line-2"
        class="form-control"
        formControlName="address2"
        placeholder="Apt, Suite, Floor"
        aria-labelledby="address-label"
        data-cy="address-line-2-input"
      />
    </div>
  </div>

  <om-address-basic-input *ngIf="fullAddressFormVisible" [address]="address"></om-address-basic-input>
</form>
