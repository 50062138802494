import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { PatientPreferencesAndAppointments } from './__generated__/patient-preferences-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class PatientPreferencesGraphqlService extends Query<PatientPreferencesAndAppointments> {
  document = gql`
    query PatientPreferencesAndAppointments($appointmentsLimit: Int) {
      patient {
        id
        healthkitAuthorized
        fitbitAuthorized

        upcomingAppointmentsForConnectedDeviceDiscussion: appointments(
          strategies: [UPCOMING, CURRENT]
          sortOrder: ASC
          first: $appointmentsLimit
        ) {
          nodes {
            id
            startAt
          }
        }
        mostRecentHealthkitMeasurement {
          ...MostRecentHealthKitMeasurement
        }
        mostRecentFitbitMeasurement {
          ...MostRecentFitbitMeasurement
        }
        preferences {
          id
          connectedDevicesOptIn
        }
      }
    }

    fragment MostRecentFitbitMeasurement on Measurement {
      id
      collectedAt
    }

    fragment MostRecentHealthKitMeasurement on Measurement {
      id
      collectedAt
    }
  `;
}
