import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { REDEEM_MEMBERSHIP_FROM_EMAIL } from '@app/core/forgot-password/forgot-password.component';
import { HORNBILL_CLAIM_CODE_PARAM_KEY } from '@app/shared/hornbill-test-params';

import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private attemptedPathService: AttemptedPathService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const authenticated$ = this.authService.initialized$.pipe(
      map(() => {
        if (this.authService.isTokenBlank()) {
          this.handleUnauthenticated(state.url, route);
          return false;
        }
        return true;
      }),
    );

    return authenticated$.pipe(
      concatMap(authenticated => {
        if (!authenticated) {
          return observableOf(false);
        }
        return observableOf(true);
      }),
      catchError(() => this.handleUnauthenticated(state.url, route)),
    );
  }

  private handleUnauthenticated(url: string, route: ActivatedRouteSnapshot) {
    this.analyticsService.trackDirectLinksToLoggedInResources(url);
    this.attemptedPathService.setAttemptedPath(url);

    this.authService.goLogin(
      url,
      !!route?.queryParams[HORNBILL_CLAIM_CODE_PARAM_KEY] ? REDEEM_MEMBERSHIP_FROM_EMAIL : undefined,
    );
    return observableOf(false);
  }
}
