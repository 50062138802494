import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { AuthService } from '@app/core/auth.service';
import { ConfigService } from '@app/core/config.service';
import { MembershipType } from '@app/registration/enterprise/membership-type';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { ToastComponent } from '@app/shared';
import { OmRecaptchaComponent } from '@app/shared/recaptcha/om-recaptcha.component';
import { ToastService } from '@app/shared/toast.service';

import { EnterpriseRegistration } from './enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from './enterprise-registration-analytics.service';
import { RegistrationStepDirective } from './registration-step.directive';

@Component({
  selector: 'om-registration-container',
  styleUrls: ['../../shared/form-input.scss'],
  templateUrl: './registration-container.component.html',
})
export class RegistrationContainerComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @Input() initialStepName = StepName.workEmail;
  @ViewChild('captcha', { static: true }) captcha: OmRecaptchaComponent;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  private component: RegistrationStepDirective;
  private componentSubmitSubscription: Subscription;
  private componentLogInSubscription: Subscription;
  private destroy$ = new Subject<void>();
  private stepSubmitSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public attemptedPathService: AttemptedPathService,
    public authService: AuthService,
    public config: ConfigService,
    private enterpriseRegistration: EnterpriseRegistration,
    private router: Router,
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private toastService: ToastService,
  ) {}

  ngOnInit() {
    this.processQueryParams();
    this.setInitialStep();
    this.toastService.toastMessage$.pipe(takeUntil(this.destroy$)).subscribe({
      next: message => this.toastComponent.show(message),
    });
    this.enterpriseRegistration.setCurrentStep(this.initialStepName);
  }

  ngAfterContentInit() {
    if (!this.fastForwarding) {
      this.loadComponent();
    }
  }

  ngAfterViewInit(): void {
    this.enterpriseRegistration.setUser();
    if (this.fastForwarding) {
      this.fastForward();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadComponent() {
    if (this.componentSubmitSubscription) {
      this.componentSubmitSubscription.unsubscribe();
    }
    if (this.stepSubmitSubscription) {
      this.stepSubmitSubscription.unsubscribe();
    }
    if (this.componentLogInSubscription) {
      this.componentLogInSubscription.unsubscribe();
    }
    if (!this.enterpriseRegistration.getCurrentStep()) {
      return;
    }

    const step = this.enterpriseRegistration.getCurrentStep();
    const componentClass = step.component;
    this.container.clear();
    const compRef = this.container.createComponent(componentClass);
    this.component = compRef.instance;
    step.initComponent(<RegistrationStepDirective>compRef.instance, this.enterpriseRegistration);
    if (step.shouldFastForward) {
      this.fastForwarding = true;
      this.onSubmit();
    } else {
      this.enterpriseRegistration
        .getCurrentStep()
        .trackPageVisit(this.enterpriseRegistration, this.enterpriseRegistrationAnalyticsService);
      this.componentSubmitSubscription = this.component.submit.subscribe(() => {
        this.onSubmit();
      });
      this.component.isLoggedIn = this.isLoggedIn;
      this.componentLogInSubscription = this.component.login.subscribe(() => this.handleLogIn());
    }
  }

  percentComplete() {
    return this.enterpriseRegistration.percentComplete();
  }

  canGoBack() {
    return this.enterpriseRegistration.canGoBack();
  }

  onSubmit() {
    this.stepSubmitSubscription = this.enterpriseRegistration
      .getCurrentStep()
      .submit(this.enterpriseRegistration, this.captcha)
      .subscribe(
        (result: any) => {
          if (this.fastForwarding && !this.enterpriseRegistration.onSuccessStep()) {
            this.onSubmit();
          } else {
            this.fastForwarding = false;
            if (result) {
              this.loadComponent();
            }
          }
        },
        (err: Error) => {
          if (this.fastForwarding) {
            this.fastForwarding = false;
            this.enterpriseRegistration.getCurrentStep().shouldFastForward = false;
            this.loadComponent();
          } else {
            this.component.onError(err);
          }
        },
      );
  }

  onBack() {
    if (this.enterpriseRegistration.canGoBack()) {
      this.enterpriseRegistration.goBack();
      this.loadComponent();
    }
  }

  fastForward() {
    this.onSubmit();
  }

  handleLogIn() {
    this.enterpriseRegistrationAnalyticsService.loginClicked({
      module: this.enterpriseRegistration.getCurrentStep().MODULE,
      isWhitelist: this.enterpriseRegistration.isWhitelisted,
    });

    const params = this.enterpriseRegistration.redirectParams();
    this.authService.goLogin(`${this.pathName()}?${params}`);
  }

  pathName(): string {
    return window.location.pathname;
  }

  get form(): UntypedFormGroup {
    return this.enterpriseRegistration.form;
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  get showLoginLink(): boolean {
    return this.enterpriseRegistration.showLoginLink();
  }

  get queryParams(): Params {
    return this.route.snapshot.queryParams;
  }

  get hasQueryParams(): boolean {
    return Object.keys(this.queryParams).length > 0;
  }

  get isFromEloqua(): boolean {
    const { utm_campaign, utm_medium } = this.route.snapshot.queryParams;
    return utm_medium === 'email' && utm_campaign;
  }

  get fastForwarding(): boolean {
    return this.enterpriseRegistration.fastForwarding;
  }

  set fastForwarding(value) {
    this.enterpriseRegistration.fastForwarding = value;
  }

  private processQueryParams(): void {
    if (this.hasQueryParams) {
      if (this.isFromEloqua) {
        this.prefillFromEloqua();
      } else {
        this.enterpriseRegistration.patchParams(this.queryParams);
        this.fastForwarding = true;
      }
    }
  }

  private setInitialStep(): void {
    const { initialStepName } = this.route.snapshot.data;

    // In order to preserve fast forwarding logic, don't short circuit fast
    // forwarding if the initial step name is the default work email step.
    if (initialStepName && initialStepName !== StepName.workEmail.valueOf()) {
      this.initialStepName = initialStepName;
      this.fastForwarding = false;
    }
  }

  private prefillFromEloqua(): void {
    const { email, employee_id, discount_code, b2b_company_id } = this.queryParams;

    this.enterpriseRegistration.prefillFromEloqua = true;
    this.enterpriseRegistration.patchParams({ email, employee_id, membershipType: MembershipType.PERSONAL });
    this.fastForwarding = true;

    if (discount_code) {
      this.enterpriseRegistration.patchParams({ discount_code, b2b_company_id });
    } else if (b2b_company_id) {
      this.enterpriseRegistration.patchParams({ b2b_company_id });
    }
  }
}
