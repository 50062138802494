import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of as observableOf, Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { AuthService } from '@app/core/auth.service';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { DocumentSigner } from '@app/core/legal-documents';
import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { MembershipAction, MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import { PrepaidEnrollmentMembershipService } from '@app/membership/prepaid-enrollment-membership.service';
import { Coupon } from '@app/shared/coupon';
import { SendAppLinkGraphQLService } from '@app/shared/download-app-card/send-app-link-graphql.service';
import { HORNBILL_INVITE_SOURCE_FLOW_KEY, PrepaidInviteSourceFlow } from '@app/shared/hornbill-test-params';
import { ServiceArea } from '@app/shared/service-area';
import { ServiceAreaService } from '@app/shared/service-area.service';
import { User } from '@app/shared/user';

import { EnterpriseRegistration } from './enterprise/enterprise-registration';
import { MembershipType } from './enterprise/membership-type';
import { PediatricMembershipTypes, PediatricRegistrationTypes } from './pediatric/pediatric-membership-type.service';
import {
  ChildRegCompleteProperties,
  DiscountTrackingProperties,
  RegInputEnteredProperties,
  RegistrationAnalyticsService,
} from './registration-analytics.service';
import { RegistrationService } from './registration.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationStateService {
  patient: User;
  planId: number;
  regComplete = false;
  hasRegCompleteError = false;
  membershipType: PediatricMembershipTypes;
  pediatricRegType: PediatricRegistrationTypes;
  documentSigner: DocumentSigner;
  nonmemberRegistration = false;
  serviceAreas$: Observable<ServiceArea[]>;
  textAppToPhone = false;
  isConsumerPromoAvailable = false;
  isFromEnterpriseRegistration = false;
  coupon: Coupon | null = null;
  prepaidClaimCode: string | null = null;
  hasAvailableInvites = false;
  canInviteMembers = false;
  prepaidEnrollmentEnabled: boolean;
  discountTrackingProperties: DiscountTrackingProperties;
  isPrepaidEnrollment = false;
  hideChildAccountDetails = false;

  accountCreationQuestion = 'child-account-details';
  registrationCompleteQuestion = 'collect-payment-method';
  allowBackButton = false;
  currentQuestionIndex = 0;
  questions = [
    'pediatric-service-area',
    'child-intake',
    'guardian-confirmation',
    'child-account-details',
    'on-behalf-terms-of-service',
    'collect-payment-method',
    'mfa-step',
    'email-verification',
  ];

  questionsAnalyticsModuleMap: Record<string, string> = {
    'pediatric-service-area': 'Service Area Page',
    'child-intake': 'Child Info Page',
    'guardian-confirmation': 'Guardian Info Page',
    'child-account-details': 'Child Account Creation Page',
    'on-behalf-terms-of-service': 'Peds TOS Page',
    'collect-payment-method': 'Billing Page',
    'mfa-step': 'MFA Step',
    'email-verification': 'Email Verification',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private enterpriseRegistration: EnterpriseRegistration,
    private launchDarklyService: LaunchDarklyService,
    private linksService: LinksService,
    private membershipService: MembershipService,
    private prepaidEnrollmentMembershipService: PrepaidEnrollmentMembershipService,
    private registrationAnalyticsService: RegistrationAnalyticsService,
    private registrationService: RegistrationService,
    private router: Router,
    private sendAppLinkGraphQLService: SendAppLinkGraphQLService,
    private serviceAreaService: ServiceAreaService,
    private userService: UserService,
  ) {
    this.patient = new User();
    this.serviceAreas$ = this.serviceAreaService.getServiceAreas(this.enterpriseRegistration.b2bCompanyId).pipe(
      take(1),
      tap(serviceAreas => {
        this.enterpriseRegistration.setServiceArea(serviceAreas[0]);
      }),
    );
    this.launchDarklyService
      .featureFlag$(FeatureFlags.HORNBILL_ENROLLMENT_P0_ENABLED, false)
      .pipe(take(1))
      .subscribe((hornbillEnrollmentEnabled: boolean) => {
        this.prepaidEnrollmentEnabled = hornbillEnrollmentEnabled;
      });
    this.launchDarklyService
      .featureFlag$(FeatureFlags.PEDS_REGISTRATION_HIDE_ACCOUNT_DETAILS, false)
      .pipe(take(1))
      .subscribe((hideAccountDetails: boolean) => {
        this.hideChildAccountDetails = hideAccountDetails && this.authService.isLoggedIn();
      });
  }

  get isConsumerMembership(): boolean {
    return this.membershipType === PediatricMembershipTypes.Consumer;
  }

  get isConsumerRegistration(): boolean {
    return this.pediatricRegType === PediatricRegistrationTypes.Consumer;
  }

  get needsPediatricClaimCode(): boolean {
    return (
      this.prepaidEnrollmentEnabled &&
      !this.prepaidClaimCode &&
      this.pediatricRegType === PediatricRegistrationTypes.PrepaidEnrollment &&
      this.hasAvailableInvites
    );
  }

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  get currentQuestion(): string {
    return this.questions[this.currentQuestionIndex];
  }

  get currentAnalyticsModule(): string {
    return this.questionsAnalyticsModuleMap[this.currentQuestion];
  }

  get lastQuestionWasEmailVerification(): boolean {
    return this.questions[this.questions.length - 1] === 'email-verification';
  }

  get onLastQuestion(): boolean {
    return this.currentQuestionIndex === this.questions.length - 1;
  }

  get progress(): number {
    return (100 * (this.currentQuestionIndex + 1)) / this.questions.length;
  }

  /**
   * Initialize the reg state service based on the provided registration type (prepaid, consumer, or enterprise).
   *
   * @param registrationType
   * @param prepaidClaimCode
   */
  initializeRegisteringOnBehalfWithRegType(
    registrationType: PediatricRegistrationTypes,
    prepaidClaimCode: string | null = null,
  ) {
    this.setupRegistrationWithType(registrationType, prepaidClaimCode);
    this.completeRegSetupWithType();
  }

  /**
   * Initialize the reg state service based on whether the user is going through enterprise registration.
   * TODO: Remove when hornbill-enrollment-p0-enabled is removed
   *
   * @deprecated
   * @param isEnterprise
   */
  initializeRegisteringOnBehalf(isEnterprise: boolean) {
    this.setupRegistrationType(isEnterprise);
    this.completeRegSetupWithType();
  }

  nextQuestion() {
    if (this.currentQuestion === this.registrationCompleteQuestion) {
      this.markRegistrationComplete();
    }
    this.currentQuestionIndex += 1;
    this.updateAllowBackButton();
    if (this.currentQuestionIndex === this.questions.length) {
      this.redirectToCompletionPage();
    }
  }

  previousQuestion() {
    if (this.currentQuestionIndex === 0) {
      window.history.back();
    } else {
      this.currentQuestionIndex -= 1;
      this.updateAllowBackButton();
    }
  }

  regInputEntered(props: Partial<RegInputEnteredProperties>) {
    const propsWithMemType = {
      ...props,
      membershipType: this.membershipType,
    } as RegInputEnteredProperties;
    this.registrationAnalyticsService.regInputEntered(propsWithMemType);
  }

  /**
   * Override the built-in logic for hiding/showing the back button during the registration flow
   *
   * @param value
   */
  setAllowBackButton(value: boolean) {
    this.allowBackButton = value;
  }

  /*
    Prevents users from going back if on first question or any question after account creation
                      ▼ account creation step [4]
    | 0 | 1 | 2 | 3 | 4 | 5 | 6 |
    | n | y | y | y | y | n | n |
  */
  updateAllowBackButton() {
    const onFirstQuestion = this.currentQuestionIndex === 0;
    const onQuestionAfterAcctCreation =
      this.currentQuestionIndex > this.questions.indexOf(this.accountCreationQuestion);

    this.allowBackButton = !onFirstQuestion && !onQuestionAfterAcctCreation && !this.regComplete;
  }

  createPediatricUser() {
    return this.authService.isLoggedIn()
      ? this.userService.createPediatricUserForPatient(this.patient, this.planId, this.prepaidClaimCode)
      : this.createPediatricUserForNonmember();
  }

  /**
   * Configure and store the user that is currently being registered. This is not
   * necessarily the same as the logged-in user!
   *
   * @param user
   */
  setRegistrant(user: User) {
    this.patient.id = user.id;
    this.patient.email = user.email;
    this.patient.membershipId = user.membershipId;

    const signingRelationship = user.relationships[0];
    const guardian = this.patient.pediatricEmergencyContact.contact;

    this.documentSigner = {
      email: guardian.email,
      firstName: guardian.firstName,
      lastName: guardian.lastName,
      id: signingRelationship.administrator.id,
      type: signingRelationship.administratorType,
    };
  }

  resetState() {
    this.currentQuestionIndex = 0;
  }

  /**
   * Shared setup that should always happen, regardless of whether hornbill is enabled or not
   */
  private completeRegSetupWithType() {
    this.regComplete = false;
    this.setLoggedInStateAttributes();
    this.configurePostRegQuestions();

    if (this.hideChildAccountDetails) {
      this.removeQuestionByName('child-account-details');
      this.accountCreationQuestion = 'guardian-confirmation';
    }
  }

  private setLoggedInStateAttributes() {
    if (this.authService.isLoggedIn()) {
      this.setLoggedInRegistrationStateAttributes();
    } else {
      this.setLoggedOutRegistrationStateAttributes();
    }
  }

  private configurePostRegQuestions() {
    combineLatest([
      this.launchDarklyService.featureFlag$(
        FeatureFlags.CONSUMER_PEDIATRIC_REGISTRATION_EMAIL_VERIFICATION_STEP,
        false,
      ),
      this.launchDarklyService.featureFlag$(FeatureFlags.REGISTRATION_MFA_PEDIATRIC, false),
      this.launchDarklyService.featureFlag$(FeatureFlags.REGISTRATION_MFA_PEDIATRIC_DSU, false),
    ])
      .pipe(take(1))
      .subscribe(([verifyEmailEnabled, pediatricMfaEnabled, pediatricDsuMfaEnabled]) => {
        const mfaEnabledForPediatricFlow = !this.authService.isLoggedIn()
          ? pediatricMfaEnabled
          : pediatricDsuMfaEnabled;
        if (!mfaEnabledForPediatricFlow) {
          this.removeQuestionByName('mfa-step');
        }

        if (!verifyEmailEnabled) {
          this.removeQuestionByName('email-verification');
        }
      });
  }

  private createPediatricUserForNonmember() {
    if (this.enterpriseRegistration.b2bCompanyId) {
      return this.userService.createPediatricUserForEnterpriseNonmember(
        this.patient,
        this.patient.enterpriseRegistrationDetails,
      );
    } else if (this.prepaidClaimCode) {
      return this.userService.createPediatricUserForPrepaidNonmember(this.patient, this.prepaidClaimCode);
    } else {
      return this.userService.createPediatricUserForConsumerNonmember(this.patient);
    }
  }

  private redirectToCompletionPage() {
    this.isChildMemberManagerWithInvites()
      .pipe(take(1))
      .subscribe({
        next: (isChildMemberManagerWithSeats: boolean) => {
          if (this.startedInInviteFlow || isChildMemberManagerWithSeats) {
            this.redirectToPrepaidInviteFlow(isChildMemberManagerWithSeats);
          } else {
            this.redirectToChildRegComplete();
          }
          if (this.textAppToPhone) {
            this.textAppLinkToPhone();
          }
        },
        error: () => {
          this.redirectToChildRegComplete();
        },
      });
  }

  private markRegistrationComplete() {
    this.updateWhenAmazonMembership()
      .pipe(
        take(1),
        switchMap(() => this.registrationService.markRegComplete()),
      )
      .subscribe({
        next: () => {
          this.regComplete = true;
          if (this.membershipType !== PediatricMembershipTypes.Enterprise) {
            this.sendRegCompleteAnalytics();
          }
        },
        error: () => {
          this.hasRegCompleteError = true;
        },
      });
  }

  private updateWhenAmazonMembership() {
    if (this.prepaidEnrollmentEnabled && this.pediatricRegType === PediatricRegistrationTypes.PrepaidEnrollment) {
      return this.membershipService.updatePediatricAmazonMembership$(
        this.patient,
        this.prepaidClaimCode,
        MembershipAction.New,
      );
    }

    return observableOf(null);
  }

  private sendRegCompleteAnalytics() {
    const mpParams: ChildRegCompleteProperties = {
      membershipType: this.membershipType,
      module: this.currentAnalyticsModule,
      isLoggedIn: this.authService.isLoggedIn(),
      isPrepaidEnrollment: this.isPrepaidEnrollment && this.prepaidEnrollmentEnabled,
      serviceArea: this.patient?.serviceArea?.name,
    };
    this.registrationAnalyticsService.trackChildRegCompleted(mpParams);
  }

  private isChildMemberManagerWithInvites() {
    if (
      this.prepaidEnrollmentEnabled &&
      this.pediatricRegType === PediatricRegistrationTypes.PrepaidEnrollment &&
      this.nonmemberRegistration
    ) {
      return this.prepaidEnrollmentMembershipService.canInviteMembers$();
    }

    return observableOf(false);
  }

  private get startedInInviteFlow() {
    return (
      this.prepaidEnrollmentEnabled &&
      this.canInviteMembers &&
      !!this.activatedRoute.snapshot.queryParams[HORNBILL_INVITE_SOURCE_FLOW_KEY]
    );
  }

  private textAppLinkToPhone() {
    this.sendAppLinkGraphQLService.mutate({ input: { phoneNumber: this.patient.phoneNumber } }).subscribe();
  }

  private redirectToChildRegComplete() {
    if (this.coupon) {
      this.router.navigate(['/registration/child-registration-complete'], {
        queryParams: { discount_code: this.coupon.id },
      });
    } else {
      this.router.navigate(['/registration/child-registration-complete']);
    }
  }

  private redirectToPrepaidInviteFlow(addQueryParamSource: boolean) {
    if (
      this.activatedRoute.snapshot.queryParams[HORNBILL_INVITE_SOURCE_FLOW_KEY] ===
      PrepaidInviteSourceFlow.MembershipSettings
    ) {
      this.router.navigate([this.linksService.membershipSettings]);
    } else {
      this.router.navigate([this.linksService.prepaidInvite], {
        queryParams: {
          ...this.activatedRoute.snapshot.queryParams,
          ...(addQueryParamSource && { sourceFlow: PrepaidInviteSourceFlow.Registration }),
        },
      });
    }
  }

  private setupRegistrationWithType(registrationType: PediatricRegistrationTypes, prepaidClaimCode: string | null) {
    if (registrationType === PediatricRegistrationTypes.Enterprise) {
      this.setupEnterprisePediatricRegistration();
    } else if (registrationType === PediatricRegistrationTypes.PrepaidEnrollment) {
      this.setupPrepaidEnrollmentRegistration(prepaidClaimCode);
    } else {
      this.setupConsumerPediatricRegistration();
    }
  }

  // TODO:BHX: Remove when hornbill-enrollment-p0-enabled is removed
  private setupRegistrationType(enterprise: boolean) {
    if (enterprise) {
      this.setupEnterprisePediatricRegistration();
    } else {
      this.setupConsumerPediatricRegistration();
    }
  }

  private setLoggedInRegistrationStateAttributes() {
    this.nonmemberRegistration = false;
    combineLatest([this.membershipService.getMembership(), this.userService.getUser()])
      .pipe(take(1))
      .subscribe(([membership, user]: [Membership, User]) => {
        this.patient.serviceArea = user.serviceArea;
        this.removeQuestionByName('pediatric-service-area');

        if (membership.isB2b()) {
          this.planId = membership.planId;
        }
        this.hasAvailableInvites = membership.canInviteAdditionalSeatHolders();
        this.canInviteMembers = membership.isGroupMembershipManager() && membership.hasMultipleSeats();
      });
  }

  private setLoggedOutRegistrationStateAttributes() {
    this.nonmemberRegistration = true;
  }

  private setupConsumerPediatricRegistration() {
    this.patient = new User();

    this.membershipType = PediatricMembershipTypes.Consumer;
    this.pediatricRegType = PediatricRegistrationTypes.Consumer;
  }

  private setupEnterprisePediatricRegistration() {
    const startedInEnterpriseReg = this.enterpriseRegistration.details.membershipType === MembershipType.KIDS;
    this.patient = startedInEnterpriseReg ? this.enterpriseRegistration.patient : new User();

    this.membershipType = PediatricMembershipTypes.Enterprise;
    this.pediatricRegType = PediatricRegistrationTypes.Enterprise;

    this.serviceAreas$.subscribe({
      next: serviceAreas => {
        if (serviceAreas.length === 1) {
          this.removeQuestionByName('pediatric-service-area');
        }
      },
    });
    this.removeQuestionByName('collect-payment-method');
    this.registrationCompleteQuestion = 'on-behalf-terms-of-service';
  }

  private setupPrepaidEnrollmentRegistration(prepaidClaimCode: string | null) {
    this.patient = new User();

    this.membershipType = PediatricMembershipTypes.Consumer;
    this.pediatricRegType = PediatricRegistrationTypes.PrepaidEnrollment;
    this.isPrepaidEnrollment = true;
    this.prepaidClaimCode = prepaidClaimCode;

    this.removeQuestionByName('collect-payment-method');
    this.registrationCompleteQuestion = 'on-behalf-terms-of-service';
  }

  private removeQuestionByName(question: string) {
    const questionIndex = this.questions.indexOf(question);
    if (questionIndex !== -1) {
      this.questions.splice(questionIndex, 1);
    }
  }
}
