import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Rollbar from 'rollbar';
import { combineLatest, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { FooterService } from '@app/core/footer.service';
import { LinksService } from '@app/core/links.service';
import { NavbarService } from '@app/core/navbar.service';
import { RollbarService } from '@app/core/rollbar.service';
import { FlashService } from '@app/shared/flash.service';
import { UserForLoggedInWrapperInitializationGraphQLService } from '@app/shared/logged-in-wrapper/user-for-logged-in-wrapper-initialization-graphql.service';
import { BannerType } from '@omgui/omgui-banner/omgui-banner.component';

import { NativeAppService } from '../native-app.service';
import { ToastService } from '../toast.service';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'om-logged-in-wrapper',
  templateUrl: './logged-in-wrapper.component.html',
  styleUrls: ['./logged-in-wrapper.component.scss'],
})
export class LoggedInWrapperComponent implements OnInit, OnDestroy {
  @ViewChild(ToastComponent) toastComponent: ToastComponent;

  closed = false;
  shouldHideFooter: boolean;
  onHomePage = false;
  hideFooter$: Observable<boolean>;
  hideNavigation$: Observable<boolean>;
  initialized$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  readonly BannerType = BannerType;

  constructor(
    private nativeAppService: NativeAppService,
    private route: ActivatedRoute,
    private router: Router,
    private flashService: FlashService,
    private toastService: ToastService,
    private navbarService: NavbarService,
    private footerService: FooterService,
    private links: LinksService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private userForLoggedInWrapperInitialization: UserForLoggedInWrapperInitializationGraphQLService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.initToast();

    this.onHomePage = this.router.url === this.links.home;
    this.hideNavigation$ = this.navbarService.shouldHideNavbar$();
    this.hideFooter$ = this.footerService.shouldHideFooter$();

    this.initialized$ = combineLatest([this.identifyUserInRollbar$(), this.launchDarklyService.userIdentified$]).pipe(
      map(() => true),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private identifyUserInRollbar$(): Observable<boolean> {
    return this.userForLoggedInWrapperInitialization.watch().valueChanges.pipe(
      map(result => result.data),
      tap(({ patient, principalUser }) => {
        this.rollbar.configure({
          payload: {
            person: {
              id: patient.id,
              principalUserId: principalUser.id,
            },
          },
        });
      }),
      map(() => true),
      catchError(() => observableOf(true)),
    );
  }

  private initToast() {
    this.toastService.toastMessage$.pipe(takeUntil(this.destroy$)).subscribe({
      next: message => this.toastComponent.show(message),
    });
  }
}
