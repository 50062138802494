import {
  Profile_patient_profileInfo_addresses,
  Profile_patient_profileInfo_emergencyContacts,
  Profile_patient_profileInfo_phoneNumbers,
} from '@app/account/profile/__generated__/Profile';
import { HEALTH_HISTORY_ADULT_SURVEY_ID, HEALTH_HISTORY_PEDIATRIC_SURVEY_ID } from '@app/core/global-constants';
import { Discount } from '@app/shared/discount';
import { PatientPharmacy, PatientPharmacyResponse } from '@app/shared/patient-pharmacy';
import { Profile, UserType } from '@omgui/omgui-profile-bubble/omgui-profile-bubble.component';

import { Address } from '../shared/address';
import { Hearabout } from '../shared/hearabout';
import { ServiceArea } from '../shared/service-area';
import { Provider } from './provider';

export class User implements Profile {
  constructor() {
    this.address = new Address();
  }

  static readonly fieldMapping = {
    id: 'id',
    firstName: 'first_name',
    lastName: 'last_name',
    preferredName: 'preferred_name',
    nickname: 'nickname',
    gender: 'gender',
    genderDetails: 'gender_details',
    rawGender: 'raw_gender',
    dob: 'dob',
    phoneNumber: 'phone_number',
    email: 'email',
    preferredEmail: 'preferred_email',
    password: 'password',
    termsOfServiceAccepted: 'terms_of_service_accepted',
    hearaboutId: 'hearabout_id',
    hearaboutOther: 'hearabout_other',
    isRegComplete: 'is_reg_complete',
    regFlowVersion: 'reg_flow_version',
    profileImageUrl: 'profile_image_url',
    type: 'type',
    memberSince: 'member_since_at',
    officeId: 'office_id',
    employerName: 'emp_name',
    hasAccessToOnsite: 'has_access_to_onsite',
    isDirectSignupEligible: 'is_direct_signup_eligible',
    isVirtual: 'is_virtual',
    referralLinkWebSocial: 'referral_link_web_social',
    referralDiscount: 'referral_discount',
    ageInYears: 'age_in_years',
    hasUnregisteredDependents: 'has_unregistered_dependents',
    whitelistedEmployee: 'whitelisted_employee',
    membershipId: 'membership_id',
    membershipPlanType: 'membership_plan_type',
    sameAddress: 'same_address',
    accessToken: 'accessToken',
    isAmazonUser: 'is_amazon_user',
  };

  id: number;
  firstName: string;
  lastName: string;
  preferredName: string;
  nickname: string;
  gender: string;
  genderDetails: string;
  rawGender: boolean;
  dob: string;
  dueDate: string;
  unborn: boolean;
  phoneNumber: string;
  email: string;
  preferredEmail: string;
  password: string;
  serviceArea: ServiceArea;
  serviceAreaId: number;
  hasUnregisteredDependents: boolean;
  hearabout: Hearabout;
  hearaboutId: number;
  hearaboutOther: string;
  isRegComplete: boolean;
  regFlowVersion: string;
  isNewConsumerRegistration: boolean;
  termsOfServiceAccepted: boolean;
  address: Address;
  sameAddress?: boolean;
  profileImageUrl: string;
  type: UserType;
  provider: Provider;
  membershipId: number;
  membershipPlanType: string;
  memberSince: string;
  memberSinceYear: string;
  officeId: number;
  employerName: string;
  employerZipCode: string;
  hasAccessToOnsite: boolean;
  accessToken: string;
  patientPharmacies: PatientPharmacy[];
  isDirectSignupEligible: boolean;
  isVirtual: boolean;
  isAmazonUser: boolean;
  referralLinkWebSocial: string;
  referralDiscount: Discount;
  ageInYears: number;
  pediatricEmergencyContact: EmergencyContact;
  relationships: Relationship[];
  whitelistedEmployee: boolean;
  enterpriseRegistrationDetails?: EnterpriseRegistrationDetails;
  pronounGroup: string;
  sex: string;
  phoneNumbers: Profile_patient_profileInfo_phoneNumbers[];
  addresses: Profile_patient_profileInfo_addresses[];
  emergencyContacts: Profile_patient_profileInfo_emergencyContacts[];

  static fromApiV2(response: Record<string, any>): User {
    const user = new User();
    user.id = response['id'];
    user.firstName = response['first_name'];
    user.lastName = response['last_name'];
    user.preferredName = response['preferred_name'];
    user.nickname = response['nickname'];
    user.gender = response['gender'];
    user.genderDetails = response['gender_details'];
    user.rawGender = response['raw_gender'];
    user.dob = response['dob'];
    user.phoneNumber = response['phone_number'];
    user.email = response['email'];
    user.preferredEmail = response['preferred_email'];
    user.password = response['password'];
    user.termsOfServiceAccepted = response['terms_of_service_accepted'];
    user.hearaboutId = response['hearabout_id'];
    user.hearaboutOther = response['hearabout_other'];
    user.isRegComplete = response['is_reg_complete'];
    user.regFlowVersion = response['reg_flow_version'];
    user.profileImageUrl = response['profile_image_url'];
    user.type = response['type'];
    user.memberSince = response['member_since_at'];
    user.officeId = response['office_id'];
    user.employerName = response['emp_name'];
    user.hasAccessToOnsite = response['has_access_to_onsite'];
    user.isDirectSignupEligible = response['is_direct_signup_eligible'];
    user.isVirtual = response['is_virtual'];
    user.referralLinkWebSocial = response['referral_link_web_social'];
    user.referralDiscount = response['referral_discount'];
    user.ageInYears = response['age_in_years'];
    user.hasUnregisteredDependents = response['has_unregistered_dependents'];
    user.whitelistedEmployee = response['whitelisted_employee'];
    user.membershipId = response['membership_id'];
    user.membershipPlanType = response['membership_plan_type'];
    user.sameAddress = response['same_address'];
    user.accessToken = response['accessToken'];
    user.isAmazonUser = response['is_amazon_user'];

    if (response.provider_team) {
      user.provider = Provider.fromApiV2(response.provider_team);
    }

    if (response.member_since_at) {
      user.memberSinceYear = user.memberSince.split('-', 1)[0];
    }

    if (response.hearabout_id) {
      user.hearabout = new Hearabout({ id: response.hearabout_id });
    }

    if (response.service_area) {
      user.serviceArea = ServiceArea.fromApiV2(response.service_area);
    }

    if (response.phone_number) {
      user.phoneNumber = response.phone_number.number;
    }

    if (response.access_token) {
      user.accessToken = response.access_token;
    }

    if (response.patient_pharmacies) {
      user.patientPharmacies = response.patient_pharmacies.map((patientPharmacyResponse: PatientPharmacyResponse) =>
        PatientPharmacy.fromApiV2(patientPharmacyResponse),
      );
    }

    if (response.referral_discount) {
      user.referralDiscount = new Discount(
        parseFloat(response.referral_discount.amount_off),
        parseFloat(response.referral_discount.percent_off),
      );
    }

    if (response.primary_address) {
      user.address = new Address(response.primary_address);
    }

    user.relationships = [];
    if (response.relationships_i_am_beneficiary_for) {
      user.relationships = response.relationships_i_am_beneficiary_for.map((relationshipJson: Record<string, any>) =>
        Relationship.beneficiaryRelationshipFromApiV2(relationshipJson, user),
      );
    }

    return user;
  }

  static forPediatricApiV2(user: User, recaptchaToken: string): any {
    const userForAPI: Record<string, any> = {};
    userForAPI['id'] = user.id;
    userForAPI['first_name'] = user.firstName;
    userForAPI['last_name'] = user.lastName;
    userForAPI['preferred_name'] = user.preferredName;
    userForAPI['nickname'] = user.nickname;
    userForAPI['gender'] = user.gender;
    userForAPI['gender_details'] = user.genderDetails;
    userForAPI['raw_gender'] = user.rawGender;
    userForAPI['dob'] = user.dob;
    userForAPI['phone_number'] = user.phoneNumber;
    userForAPI['email'] = user.email;
    userForAPI['preferred_email'] = user.preferredEmail;
    userForAPI['password'] = user.password;
    userForAPI['terms_of_service_accepted'] = user.termsOfServiceAccepted;
    userForAPI['hearabout_id'] = user.hearaboutId;
    userForAPI['hearabout_other'] = user.hearaboutOther;
    userForAPI['is_reg_complete'] = user.isRegComplete;
    userForAPI['reg_flow_version'] = user.regFlowVersion;
    userForAPI['profile_image_url'] = user.profileImageUrl;
    userForAPI['type'] = user.type;
    userForAPI['member_since_at'] = user.memberSince;
    userForAPI['office_id'] = user.officeId;
    userForAPI['emp_name'] = user.employerName;
    userForAPI['has_access_to_onsite'] = user.hasAccessToOnsite;
    userForAPI['is_direct_signup_eligible'] = user.isDirectSignupEligible;
    userForAPI['is_virtual'] = user.isVirtual;
    userForAPI['referral_link_web_social'] = user.referralLinkWebSocial;
    userForAPI['referral_discount'] = user.referralDiscount;
    userForAPI['age_in_years'] = user.ageInYears;
    userForAPI['has_unregistered_dependents'] = user.hasUnregisteredDependents;
    userForAPI['whitelisted_employee'] = user.whitelistedEmployee;
    userForAPI['membership_id'] = user.membershipId;
    userForAPI['membership_plan_type'] = user.membershipPlanType;
    userForAPI['same_address'] = user.sameAddress;
    userForAPI['accessToken'] = user.accessToken;
    userForAPI['is_amazon_user'] = user.isAmazonUser;

    if (user.hearabout) {
      userForAPI['hearabout_id'] = user.hearabout.id;
    }

    if (user.serviceArea) {
      userForAPI['service_area_id'] = user.serviceArea.id;
    }

    if (user.unborn || user.dueDate) {
      userForAPI['child_characteristic'] = { unborn: user.unborn, due_date: user.dueDate };
    }

    if (user.pediatricEmergencyContact) {
      userForAPI['emergency_contacts'] = [EmergencyContact.forApiV2(user.pediatricEmergencyContact)];
    }

    if (user.hasNonLoginPreferredEmail()) {
      userForAPI['emails'] = [{ email: user.preferredEmail, kind: 'other', is_preferred: true }];
    }

    if (user.address) {
      userForAPI['addresses'] = [Address.forApiV2(user.address)];
    }

    userForAPI['g-recaptcha-response'] = recaptchaToken;

    return userForAPI;
  }

  hasNonLoginPreferredEmail(): boolean {
    return !this.loginEmailIsPreferred();
  }

  loginEmailIsPreferred(): boolean {
    return this.email === this.preferredEmail;
  }

  hasOffice(selectedServiceArea: any) {
    return this.serviceArea != null && this.serviceArea.id === selectedServiceArea.id && this.officeId != null;
  }

  isPediatric(): boolean {
    return this.ageInYears < 18;
  }

  healthHistorySurveyId(): string {
    return this.isPediatric() ? HEALTH_HISTORY_PEDIATRIC_SURVEY_ID : HEALTH_HISTORY_ADULT_SURVEY_ID;
  }

  serviceAreaCode(): string {
    return this.serviceArea ? this.serviceArea.code : 'sf';
  }

  hasServiceArea(): boolean {
    return !!this.serviceArea;
  }

  isAmazonPlanType(): boolean {
    return this.membershipPlanType === 'AmazonPlan';
  }
}

export class EmergencyContact {
  constructor(contact: User) {
    this.contact = contact;
  }

  relationship: string;
  preferred: boolean;
  contact: User;

  static forApiV2(emergencyContact: EmergencyContact) {
    return {
      first_name: emergencyContact.contact.firstName,
      last_name: emergencyContact.contact.lastName,
      phone_number: { number: emergencyContact.contact.phoneNumber },
      email: { email: emergencyContact.contact.email, kind: 'other', is_preferred: true },
      address: Address.forApiV2(emergencyContact.contact.address),
      relationship_type: emergencyContact.relationship,
      is_preferred: emergencyContact.preferred,
    };
  }
}

export class Relationship {
  administrator: User | Nonmember;
  beneficiary: User | Nonmember;
  type: 'parent' | 'guardian' | 'legal_guardian';
  administratorType: string;
  beneficiaryType: string;

  static beneficiaryRelationshipFromApiV2(response: Record<string, any>, beneficiary: User): Relationship {
    const relationship = new Relationship();
    relationship.beneficiary = beneficiary;
    relationship.type = response.type;
    relationship.beneficiaryType = response.beneficiary_type;
    relationship.administratorType = response.administrator_type;

    switch (response.administrator_type) {
      case 'User':
        relationship.administrator = User.fromApiV2(response.administrator);
        break;
      case 'Nonmember':
        relationship.administrator = Nonmember.fromApiV2(response.administrator);
    }

    return relationship;
  }
}

export class Nonmember {
  id: number;
  firstName: string;
  lastName: string;
  email: string;

  static fromApiV2(response: Record<string, any>): Nonmember {
    const nonmember = new Nonmember();
    nonmember.id = response.id;
    nonmember.firstName = response.first_name;
    nonmember.lastName = response.last_name;
    nonmember.email = response.email;
    return nonmember;
  }
}

export class EnterpriseRegistrationDetails {
  b2bCompanyId: number;
  activationCode: string = null;
  workEmail: string = null;
  membershipType: string;
  whitelistedEmployeeId: number = null;
  employeeId: string = null;
  serviceAreaCode: string = null;
}
