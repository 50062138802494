import { ValidationFn } from 'ngrx-forms';
import { pattern, minLength, maxLength, required } from 'ngrx-forms/validation';

import { noSequentialCharacters } from '@app/shared/ngrx-forms-custom-validators/no-sequential-characters';

/*
Password validation regex breakdown for posterity - to make it easy for anyone to add more requirements:
- begin with ^ and end with $ to match the whole string entered
- three groups in parens starting with ? - lookahead groups validating specific requirements (more on that below)
- .* - match any character
- Lookahead groups:
  ... are groups in format "VALID/INVALID when SUBSTRING contains PATTERN".
  Positive lookahead is specified with ?=, then comes string to match (in our case it's full string - specified with .*), then comes pattern.
  Negative lookahead is specified with ?!, then comes string to match, then comes pattern.
  So in this regex -
  - Must have 1 lowercase character: (?=.*[a-z])
    - ?= means positive lookahead, .* means in full string, [a-z] is the pattern
  - Must have 1 uppercase character: (?=.*[A-Z])
    - ?= means positive lookahead, .* means in full string, [A-Z] is the pattern
  - No characters repeated more than 2 times: (?!.*(.)\1{2})
    - ?! means negative lookahead (match of the pattern is found in substring will invalidate full regex), .* means in full string, (.)\1{2} means 3 repeating characters

   Great resource for learning to grok regular expressions - https://regex101.com/
 */
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*(.)\1{2}).*$/i;

export const passwordValidators: ValidationFn<string>[] = [
  required,
  pattern(passwordRegex),
  noSequentialCharacters(3),
  minLength(8),
  maxLength(128),
];
