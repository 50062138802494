import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import includes from 'lodash-es/includes';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';
import { Dict } from 'mixpanel-browser';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { State } from '@app/app.reducer';
import { AnalyticsService } from '@app/core/analytics.service';
import { AuthService } from '@app/core/auth.service';
import { ConfigService } from '@app/core/config.service';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MembershipStatus } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import {
  LOGIN_EMAIL_FORM_FIELD,
  MODULE_ADDRESS_PAGE,
  MODULE_BILLING,
  MODULE_EMAIL_VERIFICATION_PAGE,
  MODULE_FAMILY_TYPE_PAGE,
  MODULE_PEDIATRIC_TERMS_OF_SERVICE,
  MODULE_REGISTRATION_ERROR_PAGE,
  MODULE_SERVICE_AREA_PAGE,
  MP_EVENT_AMAZON_PAYMENT_BANNER_CLICKED,
  MP_EVENT_EMAIL_UNVERIFIED,
  MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED,
  MP_EVENT_EMAIL_VERIFICATION_ACTION,
  MP_EVENT_EMAIL_VERIFICATION_ACTION_TYPE,
  MP_EVENT_EMAIL_VERIFIED,
  MP_EVENT_PAGE_VIEWED,
  MP_EVENT_REG_SUCCESSFULLY_SUBMITTED,
  MP_FLOW_REGISTRATION,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import {
  AddressSuggestionActions,
  AddressSuggestionSubmodules,
} from '@app/registration/consumer/location/location-suggestion-experiment/location-suggestion.component';
import {
  ChildBirthTypeSelectedEvent,
  ChildIntakeEvent,
  EmailVerificationEvent,
  LoggedMember,
  PediatricsEmailVerificationEvent,
  PediatricsTosAnalyticsEvent,
  PedsUnavailableModalShownEvent,
} from '@app/shared/analytics';
import { ServiceArea } from '@app/shared/service-area';

import { PREPAID_REGISTER_MP_FLOW_VERSION, REGISTER_MP_FLOW_VERSION } from './consumer/consumer-registration.constants';
import { PediatricRegistrationTypes } from './pediatric/pediatric-membership-type.service';
import { PrepaidEnrollmentProperties } from './prepaid-enrollment-analytics.service';

export interface RegInputEnteredProperties {
  formField: string;
  membershipType: string;
  module: string;
  value: any;
}

export interface DiscountTrackingProperties {
  discountCode: string;
  discountType: string;
}

export interface FamilyTypePageBannerProperties {
  primePromoBannerShown: boolean;
  primePromoBannerText: string;
}

export interface ChildRegCompleteProperties {
  membershipType: string;
  serviceArea: string;
  isLoggedIn: boolean;
  module?: string;
  isPrepaidEnrollment: boolean;
}

export enum ChildRegistrationAction {
  addAnother = 'Add Another',
  returnHome = 'Return Home',
}

@Injectable({
  providedIn: 'root',
})
export class RegistrationAnalyticsService extends AnalyticsService {
  private isFamilyFlow = false;
  private GTM_FILTERED_FIELDS = ['eventName', 'patient_id'];

  constructor(
    private config: ConfigService,
    private authService: AuthService,
    private gtmService: GoogleTagManagerService,
    protected membershipService: MembershipService,
    store: Store<State>,
    mixpanel: MixpanelService,
    launchDarklyService: LaunchDarklyService,
  ) {
    super(mixpanel, store, launchDarklyService);
  }

  trackEvent(props: any) {
    return this.trackWithDefaultProperties(props.eventName, {
      ...props,
    });
  }

  trackGTMEvent(properties: any) {
    const props = { ...this.defaultProperties, ...properties };
    this.gtmService.pushTag(props);
  }

  trackPaymentAttempt(paymentMethod: string) {
    return this.trackWithDefaultProperties('Payment Attempt', {
      paymentMethod,
      flow: 'Registration',
      module: MODULE_BILLING,
    });
  }

  regInputEntered(props: RegInputEnteredProperties) {
    return this.track('Registration Input Entered', {
      flow: MP_FLOW_REGISTRATION,
      form_field: props.formField,
      module: props.module,
      om_membership_type: props.membershipType,
      value: props.value || null,
    });
  }

  // TODO: Revisit 'PageViewed' related functions to improve usability
  // e.g change params to Record<key, value> type to allow more params to be passed through
  // and removes discountParams as a second arg
  childIntakePageViewed(
    params: ChildIntakeEvent,
    discountParams?: DiscountTrackingProperties,
    pediatricRegistrationType?: PediatricRegistrationTypes,
  ) {
    let source_property = '';
    const module_variant = params.source === 'familyFlowConfirmationPage' ? 'Me and My Child' : '';
    if (['confirmationPagePromoCard', 'familyFlowConfirmationPage'].indexOf(params.source) !== -1) {
      source_property = 'Adult Registration Confirmation Page';
      this.isFamilyFlow = true; // needed for reporting module_variant on the confirmation page
    }
    const properties = {
      flow: 'Direct Sign Up',
      module: 'Child Info Page',
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      module_variant: module_variant,
      source: source_property,
      promotion_code: discountParams?.discountCode,
      promotion_code_type: discountParams?.discountType,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    };
    this.applyMembershipCoupon$(properties).subscribe({
      next: props => this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, props),
    });
  }

  childBirthTypeSelected(params: ChildBirthTypeSelectedEvent) {
    return this.trackWithDefaultProperties('Child Birth Type Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Info Page',
      birth_type: params.unborn ? 'Unborn' : 'Born',
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
      flow_version: this.getFlowVersion(params.registrationType as PediatricRegistrationTypes),
    });
  }

  guardianConfirmationPageViewed(
    { isLoggedIn, membershipType }: LoggedMember,
    discountParams?: DiscountTrackingProperties,
    pediatricRegistrationType?: PediatricRegistrationTypes,
  ) {
    const properties = {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: membershipType,
      promotion_code: discountParams?.discountCode,
      promotion_code_type: discountParams?.discountType,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    };

    return this.applyMembershipCoupon$(properties).subscribe({
      next: props => this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, props),
    });
  }

  guardianConfirmationPageConfirmButtonClicked({ isLoggedIn }: { isLoggedIn: boolean }) {
    return this.trackWithDefaultProperties('Guardian Changes Confirmed', {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
    });
  }

  guardianConfirmationPageClosed({ isLoggedIn }: { isLoggedIn: boolean }) {
    return this.trackWithDefaultProperties('Guardian Changes Go Back', {
      flow: 'Direct Sign Up',
      module: 'Guardian Changes Modal',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
    });
  }

  childAccountCreationPageViewed(
    { isLoggedIn, membershipType }: LoggedMember,
    discountParams?: DiscountTrackingProperties,
    pediatricRegistrationType?: PediatricRegistrationTypes,
  ) {
    const properties = {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: membershipType,
      promotion_code: discountParams?.discountCode,
      promotion_code_type: discountParams?.discountType,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    };

    return this.applyMembershipCoupon$(properties).subscribe({
      next: props => this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, props),
    });
  }

  childAccountEmailLinkSelected({
    isLoggedIn,
    omMembershipType,
    registrationType,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    registrationType?: PediatricRegistrationTypes;
  }) {
    return this.trackWithDefaultProperties('Child Account Email Link Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      flow_version: this.getFlowVersion(registrationType),
    });
  }

  pedsUnavailableModalShown(
    params: PedsUnavailableModalShownEvent,
    pediatricRegistrationType?: PediatricRegistrationTypes,
  ) {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Pediatrics Location Error Modal',
      service_area: params.serviceArea.name,
      is_pediatrics: true,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    });
  }

  confirmationPageViewed({
    isLoggedIn,
    omMembershipType,
    referrer,
    discountCode,
    discountType,
    pediatricRegistrationType,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    referrer: string;
    discountCode?: string;
    discountType?: string;
    pediatricRegistrationType?: PediatricRegistrationTypes;
  }) {
    const module_variant = this.isFamilyFlow ? 'Another Kid OM Kids Special 2021' : '';
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      module: 'Child Membership Confirmation Page',
      module_variant: module_variant,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      source: referrer,
      promotion_code: discountCode,
      promotion_code_type: discountType,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    });
  }

  confirmationActionSelected({
    action,
    isLoggedIn,
    omMembershipType,
    registrationType,
  }: {
    action: ChildRegistrationAction;
    isLoggedIn: boolean;
    omMembershipType: string;
    registrationType?: PediatricRegistrationTypes;
  }) {
    return this.trackWithDefaultProperties('Child Membership Confirmation Action Selected', {
      flow: 'Direct Sign Up',
      module: 'Child Membership Confirmation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      action_selected: action,
      flow_version: this.getFlowVersion(registrationType),
    });
  }

  addressManualLinkClicked({
    isLoggedIn,
    omMembershipType,
    registrationType,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    registrationType?: PediatricRegistrationTypes;
  }) {
    return this.trackWithDefaultProperties('Address Manual Link Clicked', {
      flow: 'Direct Sign Up',
      module: 'Guardian Info Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      flow_version: this.getFlowVersion(registrationType),
    });
  }

  tosPageViewed(
    params: PediatricsTosAnalyticsEvent,
    discountParams?: DiscountTrackingProperties,
    pediatricRegistrationType?: PediatricRegistrationTypes,
  ) {
    const properties = {
      flow: 'Direct Sign Up',
      module: 'Peds TOS page',
      is_pediatrics: params.isPediatrics,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
      promotion_code: discountParams?.discountCode,
      promotion_code_type: discountParams?.discountType,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    };

    return this.applyMembershipCoupon$(properties).subscribe({
      next: props => this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, props),
    });
  }

  legalDocumentSigned(params: PediatricsTosAnalyticsEvent) {
    return this.trackMultiPlatformEvent('Legal Document Signed', {
      flow: 'Direct Sign Up',
      module: MODULE_PEDIATRIC_TERMS_OF_SERVICE,
      is_pediatrics: params.isPediatrics,
      is_logged_in: params.isLoggedIn,
      om_membership_type: params.membershipType,
      flow_version: this.getFlowVersion(params.registrationType as PediatricRegistrationTypes),
    });
  }

  existingEmailError({
    isLoggedIn,
    omMembershipType,
    registrationType,
    error,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    error: string;
    registrationType?: PediatricRegistrationTypes;
  }) {
    return this.trackWithDefaultProperties('Existing Email Error', {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      form_field: LOGIN_EMAIL_FORM_FIELD,
      error_message: error,
      flow_version: this.getFlowVersion(registrationType),
    });
  }

  activationCodeMaxedOutError({
    isLoggedIn,
    omMembershipType,
    error,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    error: string;
  }) {
    return this.trackWithDefaultProperties('Activation Code Maxed Out Error', {
      flow: 'Direct Sign Up',
      module: 'Child Account Creation Page',
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      error_message: error,
    });
  }

  serviceAreaPageViewed({
    isLoggedIn,
    omMembershipType,
    submodule,
    discountCode,
    discountType,
    experimentName,
    experimentVariationName,
    pediatricRegistrationType,
  }: {
    isLoggedIn: boolean;
    omMembershipType: string;
    submodule: string;
    discountCode?: string;
    discountType?: string;
    experimentName?: string;
    experimentVariationName?: string;
    pediatricRegistrationType?: PediatricRegistrationTypes;
  }) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_SERVICE_AREA_PAGE,
      is_whitelist: false,
      is_pediatrics: true,
      is_logged_in: isLoggedIn,
      om_membership_type: omMembershipType,
      submodule: submodule,
      promotion_code: discountCode,
      promotion_code_type: discountType,
      experiment_name: experimentName,
      experiment_variation_name: experimentVariationName,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    });
  }

  serviceAreaSelected({
    isLoggedIn,
    serviceArea,
    isPedsServiceable,
    omMembershipType,
  }: {
    isLoggedIn: boolean;
    serviceArea: ServiceArea;
    isPedsServiceable: boolean;
    omMembershipType: string;
  }) {
    return this.trackMultiPlatformEvent('Service Area Selected', {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_SERVICE_AREA_PAGE,
      is_whitelist: false,
      is_logged_in: isLoggedIn,
      service_area: serviceArea.name,
      is_peds_serviceable: isPedsServiceable,
      om_membership_type: omMembershipType,
    });
  }

  paymentPageViewed({
    membershipType,
    isLoggedIn,
    paymentAmount,
    discountCode,
    discountType,
  }: {
    membershipType: string;
    isLoggedIn: boolean;
    paymentAmount?: number;
    discountCode?: string;
    discountType?: string;
  }) {
    const properties = {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_BILLING,
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
      payment_amount: paymentAmount,
      promotion_code: discountCode,
      promotion_code_type: discountType,
    };

    return this.applyMembershipCoupon$(properties).subscribe({
      next: props => {
        this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, props);
      },
    });
  }

  paymentSubmitted({
    membershipType,
    isLoggedIn,
    paymentAmount,
  }: {
    membershipType: string;
    isLoggedIn: boolean;
    paymentAmount?: number;
  }) {
    const properties = {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_BILLING,
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
      payment_amount: paymentAmount,
    };

    this.applyMembershipCoupon$(properties).subscribe({
      next: props => {
        this.trackMultiPlatformEvent('Billing Info Submitted', props);
      },
    });
  }

  paymentSubmitError({
    membershipType,
    isLoggedIn,
    error,
    paymentAmount,
  }: {
    membershipType: string;
    isLoggedIn: boolean;
    error: string;
    paymentAmount: number;
  }) {
    return this.trackWithDefaultProperties('Submit Error Encountered', {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_BILLING,
      om_membership_type: membershipType,
      is_logged_in: isLoggedIn,
      is_whitelist: false,
      is_pediatrics: true,
      error_message: error,
      payment_amount: paymentAmount,
    });
  }

  emailVerificationPageViewed(
    params: PediatricsEmailVerificationEvent,
    pediatricRegistrationType?: PediatricRegistrationTypes,
  ) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: params.membershipType,
      service_area: params.serviceArea.name,
      is_pediatrics: true,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    };

    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, properties);
  }

  emailVerificationSuccess(params: PediatricsEmailVerificationEvent) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: params.membershipType,
      service_area: params.serviceArea.name,
      is_pediatrics: true,
      flow_version: this.getFlowVersion(params.registrationType as PediatricRegistrationTypes),
    };

    return this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFIED, properties);
  }

  emailVerificationUnverified(
    params: PediatricsEmailVerificationEvent,
    pediatricRegistrationType?: PediatricRegistrationTypes,
  ) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: params.membershipType,
      service_area: params.serviceArea.name,
      is_pediatrics: true,
      flow_version: this.getFlowVersion(pediatricRegistrationType),
    };

    return this.trackWithDefaultProperties(MP_EVENT_EMAIL_UNVERIFIED, properties);
  }

  emailVerificationLockedOut(params: PediatricsEmailVerificationEvent) {
    const properties: Dict = {
      flow: 'Post Registration',
      module: MODULE_EMAIL_VERIFICATION_PAGE,
      om_membership_type: params.membershipType,
      service_area: params.serviceArea.name,
      is_pediatrics: true,
    };

    return this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFICATION_ACCOUNT_LOCKED, properties);
  }

  emailVerificationAction(params: EmailVerificationEvent) {
    const properties: Dict = {
      flow: params.flow,
      module: params.module,
      om_membership_type: params.membershipType,
      service_area: params?.serviceArea?.name,
      is_pediatrics: params.isPediatrics,
      action_selected: params.action || MP_EVENT_EMAIL_VERIFICATION_ACTION_TYPE,
      module_variant: params.moduleVariant,
      flow_version: this.getFlowVersion(params.registrationType as PediatricRegistrationTypes),
    };

    if (params.accountLocked) {
      properties.module_variant = 'Account Locked Error State Page';
    }

    return this.trackMultiPlatformEvent(MP_EVENT_EMAIL_VERIFICATION_ACTION, properties);
  }

  familyTypePageViewed(additionalProperties?: DiscountTrackingProperties & Partial<FamilyTypePageBannerProperties>) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: MP_FLOW_REGISTRATION,
      flow_version: REGISTER_MP_FLOW_VERSION,
      module: MODULE_FAMILY_TYPE_PAGE,
      om_membership_type: 'Consumer',
      promotion_code: additionalProperties?.discountCode,
      promotion_code_type: additionalProperties?.discountType,
      prime_promo_banner_shown: additionalProperties?.primePromoBannerShown,
      prime_promo_banner_text: additionalProperties?.primePromoBannerText,
    });
  }

  trackChildRegCompleted(childRegParams: ChildRegCompleteProperties) {
    return this.trackMultiPlatformEvent(MP_EVENT_REG_SUCCESSFULLY_SUBMITTED, {
      flow: MP_FLOW_REGISTRATION,
      module: childRegParams.module,
      om_membership_type: childRegParams.membershipType,
      membership_status: MembershipStatus.CURRENT,
      service_area: childRegParams.serviceArea,
      is_pediatrics: true,
      is_logged_in: childRegParams.isLoggedIn,
      flow_version: childRegParams.isPrepaidEnrollment ? PREPAID_REGISTER_MP_FLOW_VERSION : undefined,
    });
  }

  familyTypeSelectedAction(action: string, additionalProperties: PrepaidEnrollmentProperties | null = {}) {
    return this.trackMultiPlatformEvent('Family type selected', {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_FAMILY_TYPE_PAGE,
      action_selected: action,
      ...additionalProperties,
    });
  }

  // Day One FAQ Analytics Events
  trackFamilyPageFaqExpanded(question: string) {
    return this.trackWithDefaultProperties('FAQ Question Expanded', {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_FAMILY_TYPE_PAGE,
      faq_question_clicked: question,
    });
  }

  trackFamilyPageFaqCollapsed(question: string) {
    return this.trackWithDefaultProperties('FAQ Question Collapsed', {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_FAMILY_TYPE_PAGE,
      faq_question_clicked: question,
    });
  }

  trackFamilyPageFaqLinkClicked(question: string, href: string) {
    return this.trackWithDefaultProperties('FAQ Answer Link Clicked', {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_FAMILY_TYPE_PAGE,
      faq_question_clicked: question,
      clicked_link_href: href,
    });
  }

  planTypePageViewed(discountTrackingProperties?: DiscountTrackingProperties) {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: MP_FLOW_REGISTRATION,
      module: 'Plan Type Page',
      promotion_code: discountTrackingProperties?.discountCode,
      promotion_code_type: discountTrackingProperties?.discountType,
    });
  }

  planTypeSelectedAction(action: string) {
    return this.trackMultiPlatformEvent('Plan type selected', {
      flow: MP_FLOW_REGISTRATION,
      module: 'Plan Type Page',
      action_selected: action,
    });
  }

  freeTrialCodeSubmitted(code: string) {
    return this.trackWithDefaultProperties('Free Trial Code Applied', {
      flow: MP_FLOW_REGISTRATION,
      module: 'Free Trial Code Page',
      form_field: 'Free Trial Code',
      is_trial: true,
      value: code,
    });
  }

  trackRoutingPageView() {
    return this.trackMultiPlatformEvent(MP_EVENT_PAGE_VIEWED, {
      flow: MP_FLOW_REGISTRATION,
      module: 'Routing Page',
    });
  }

  directSignupAccountCompletion({
    module,
    event,
    source,
    experiment_name,
    experiment_variant_name,
  }: {
    module: string;
    event?: string;
    source?: string;
    experiment_name?: string;
    experiment_variant_name?: string;
  }) {
    return this.trackWithDefaultProperties(event || MP_EVENT_PAGE_VIEWED, {
      flow: 'Direct Sign Up',
      om_membership_type: 'Enterprise',
      module,
      source,
      experiment_name,
      experiment_variant_name,
    });
  }

  trackVirtualOfferingModalClick(eventName: string, props: { isLoggedIn: boolean; omMembershipType: string }) {
    return this.trackWithDefaultProperties(eventName, {
      flow: MP_FLOW_REGISTRATION,
      module: MODULE_SERVICE_AREA_PAGE,
      is_pediatrics: true,
      is_logged_in: props.isLoggedIn,
      om_membership_type: props.omMembershipType,
    });
  }

  trackAddressSuggestionExperimentActions(
    eventName: AddressSuggestionActions,
    props: { submodule?: AddressSuggestionSubmodules; proposedServiceAreaType?: string },
  ) {
    const trackingParams = pickBy(
      {
        flow: MP_FLOW_REGISTRATION,
        module: MODULE_ADDRESS_PAGE,
        om_membership_type: 'Consumer',
        submodule: props?.submodule,
        proposed_service_area_type: props?.proposedServiceAreaType,
      },
      v => !isNil(v),
    );
    return this.trackWithDefaultProperties(eventName, trackingParams);
  }

  trackPrepaidEnrollmentDefaultErrorPageView(errorCode: string, claimCode: string, errorSource: string) {
    const properties = {
      flow: MP_FLOW_REGISTRATION,
      flow_version: PREPAID_REGISTER_MP_FLOW_VERSION,
      module: MODULE_REGISTRATION_ERROR_PAGE,
      error_message: errorCode,
      om_membership_type: 'Consumer',
      claim_code: claimCode,
      source: errorSource,
    };
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, properties);
  }

  trackFamilyTypeBannerClicked({ bannerText, destinationLink }: { bannerText?: string; destinationLink?: string }) {
    return this.trackWithDefaultProperties(MP_EVENT_AMAZON_PAYMENT_BANNER_CLICKED, {
      flow: MP_FLOW_REGISTRATION,
      flow_version: REGISTER_MP_FLOW_VERSION,
      module: MODULE_FAMILY_TYPE_PAGE,
      om_membership_type: 'Consumer',
      banner_text: bannerText,
      destination_link: destinationLink,
    });
  }

  /**
   * Helper method to fire an event to both MixPanel and Google Tag Manager
   *
   * @param eventName
   * @param props
   */
  private trackMultiPlatformEvent(eventName: string, props: any): Observable<any> {
    this.trackGoogleTagManagerEvent(eventName, props);
    return this.trackWithDefaultProperties(eventName, props);
  }

  private trackGoogleTagManagerEvent(eventName: string, props: any) {
    let gtmData: Record<string, any> = props;
    gtmData.event = eventName;
    gtmData = pickBy(gtmData, (value, key) => !includes(this.GTM_FILTERED_FIELDS, key));
    this.gtmService.pushTag(gtmData);
  }

  private applyMembershipCoupon$(properties: object): Observable<object> {
    if (!this.authService.isLoggedIn()) {
      return observableOf(properties);
    }

    return this.membershipService.getMembershipWithRequest().pipe(
      map(({ promotionDiscountCoupon }) => {
        if (!promotionDiscountCoupon) {
          return properties;
        }

        const { discountType, amountOff, id, percentOff } = promotionDiscountCoupon;
        return {
          promo_code: id,
          promo_code_amount: amountOff,
          promo_code_percent: percentOff,
          promo_code_type: discountType,
          ...properties,
        };
      }),
      catchError(() => observableOf(properties)),
      take(1),
    );
  }

  private getFlowVersion(pediatricRegistrationType?: PediatricRegistrationTypes): string | undefined {
    return pediatricRegistrationType === PediatricRegistrationTypes.PrepaidEnrollment
      ? PREPAID_REGISTER_MP_FLOW_VERSION
      : undefined;
  }
}
